import { createClient } from '@supabase/supabase-js';
import OpenAI from 'openai';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

interface SearchResult {
  id: string;
  content: string;
  metadata: {
    title: string;
    category: string;
    source?: string;
  };
  similarity: number;
}

export async function semanticSearch(
  query: string,
  {
    matchThreshold = 0.5,
    matchCount = 5,
    category = null
  }: {
    matchThreshold?: number;
    matchCount?: number;
    category?: string | null;
  } = {}
): Promise<SearchResult[]> {
  try {
    // Generate embedding for the query
    const embedding = await openai.embeddings.create({
      model: 'text-embedding-3-small',
      input: query,
    });

    // Search for similar documents
    const { data: documents, error } = await supabase.rpc(
      category ? 'match_documents_by_category' : 'match_documents',
      {
        query_embedding: embedding.data[0].embedding,
        match_threshold: matchThreshold,
        match_count: matchCount,
        ...(category && { category })
      }
    );

    if (error) throw error;

    return documents;
  } catch (error) {
    console.error('Error performing semantic search:', error);
    throw error;
  }
}

export async function generateSearchResponse(
  query: string,
  searchResults: SearchResult[]
): Promise<string> {
  try {
    // Combine relevant documents into context
    const context = searchResults
      .map(doc => `${doc.metadata.title}:\n${doc.content}`)
      .join('\n\n');

    // Generate response using ChatGPT
    const completion = await openai.chat.completions.create({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content: `You are an AI assistant for Glow & Go skin care clinic. Use the following information to answer questions accurately and professionally. Always provide responses in both English and Urdu. Focus on medical accuracy and safety.

Context:
${context}`
        },
        {
          role: 'user',
          content: query
        }
      ],
      temperature: 0.7,
      max_tokens: 500
    });

    return completion.choices[0].message.content;
  } catch (error) {
    console.error('Error generating search response:', error);
    throw error;
  }
}