import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, Tag, MessageCircle, Phone, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function BaldingTreatment() {
  return (
    <div className="min-h-screen bg-black pt-20">
      {/* Hero Section */}
      <div className="relative min-h-[40vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center gap-4 mb-6">
              <span className="px-3 py-1 bg-[#00B6DE]/20 text-[#00B6DE] rounded-full text-sm">
                Hair Care
              </span>
              <div className="flex items-center text-gray-400 text-sm">
                <Calendar className="w-4 h-4 mr-1" />
                Oct 11, 2024
              </div>
              <div className="flex items-center text-gray-400 text-sm">
                <Clock className="w-4 h-4 mr-1" />
                8 min read
              </div>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold gradient-text mb-6">
              Balding is Out, Full Head Of Hair Is In: Men, Listen Up!
              <span className="block text-2xl mt-4 text-[#00B6DE]">گنجا پن ختم، گھنے بال موجود: مردوں، توجہ دیں!</span>
            </h1>
          </div>
        </motion.div>
      </div>

      {/* Article Content */}
      <article className="py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div className="prose prose-invert prose-lg max-w-none">
              <p>
                Gentlemen, your hair's calling! Balding can be a tough challenge for many men, leading to self-doubt and concern. You might feel like you're losing a part of yourself as your hairline recedes. But there's hope! Effective balding treatment options are now available, allowing you to restore your hair and confidence.
                <span className="block mt-2 text-[#00B6DE]">مردو، آپ کے بال آپ کو پکار رہے ہیں! گنجا پن بہت سے مردوں کے لیے ایک مشکل چیلنج ہو سکتا ہے، جو خود اعتمادی کی کمی اور پریشانی کا باعث بنتا ہے۔</span>
              </p>

              <h2>Balding: Not the End, Just a New Beginning</h2>
              <p>
                First things first, what exactly is balding? You have probably heard the term thrown around plenty of times, but let's break it down in simple terms. Balding, or as doctors refer to it, male pattern baldness, usually begins when your hair follicles stop functioning the way they should.
                <span className="block mt-2 text-[#00B6DE]">سب سے پہلے، گنجا پن کیا ہے؟ آپ نے یہ اصطلاح کئی بار سنی ہوگی، لیکن آئیے اسے سادہ الفاظ میں سمجھتے ہیں۔</span>
              </p>

              <h2>Why Do Men and Women Lose Hair? (Spoiler: It's Not Your Fault!)</h2>
              <p>
                Have you ever wondered why your hair seems to be giving up on you? Let's clear the air. The most common reason for hair loss in men is, you guessed it, male pattern baldness (thank you, genetics!).
                <span className="block mt-2 text-[#00B6DE]">کیا آپ نے کبھی سوچا ہے کہ آپ کے بال آپ کا ساتھ کیوں چھوڑ رہے ہیں؟ آئیے حقیقت جانیں۔</span>
              </p>

              <h2>Feeling Balding Blues: The Emotional Ride</h2>
              <p>
                Many patients who visit our Lahore Hair Clinic complain about how their self-confidence is taking a hit due to severe balding and hair loss.
                <span className="block mt-2 text-[#00B6DE]">بہت سے مریض جو ہمارے لاہور ہیئر کلینک میں آتے ہیں وہ شکایت کرتے ہیں کہ شدید گنجے پن اور بالوں کے گرنے کی وجہ سے ان کا اعتماد متاثر ہو رہا ہے۔</span>
              </p>

              <h2>FUE Surgery: Your Ultimate Hair Makeover</h2>
              <p>
                The FUE hair transplant involves a skilled hair specialist who picks healthy 'seeds' or what we doctors call hair follicles, from areas of your scalp where your hair is still growing – usually from the back of your head – and plants them into the thinning or balding spots.
                <span className="block mt-2 text-[#00B6DE]">ایف یو ای ہیئر ٹرانسپلانٹ میں ایک ماہر ہیئر اسپیشلسٹ صحت مند 'بیج' یا جیسا کہ ہم ڈاکٹر اسے ہیئر فولیکلز کہتے ہیں، آپ کی کھوپڑی کے ان حصوں سے نکالتا ہے جہاں آپ کے بال ابھی بھی اگ رہے ہیں۔</span>
              </p>

              <h2>5 Reasons Why FUE Hair Transplant Is The Clear Winner</h2>
              <ol>
                <li>
                  <strong>No Big Scars:</strong> Unlike the older FUT method that leaves a big mark, FUE only leaves tiny, dot-like scars that are practically invisible.
                  <span className="block mt-2 text-[#00B6DE]">کوئی بڑے نشان نہیں: پرانے ایف یو ٹی طریقے کے برعکس جو ایک بڑا نشان چھوڑتا ہے، ایف یو ای صرف چھوٹے، نقطہ نما نشان چھوڑتا ہے جو عملی طور پر نظر نہیں آتے۔</span>
                </li>
                <li>
                  <strong>Quick Recovery:</strong> Nobody likes to be stuck in recovery mode for too long. With FUE, you'll heal faster compared to FUT.
                  <span className="block mt-2 text-[#00B6DE]">تیز بحالی: کوئی بھی زیادہ دیر تک بحالی کے مرحلے میں نہیں رہنا چاہتا۔ ایف یو ای کے ساتھ، آپ ایف یو ٹی کے مقابلے میں تیزی سے صحت یاب ہوں گے۔</span>
                </li>
                <li>
                  <strong>Perfect Hairline:</strong> FUE is all about precision. The surgeon carefully places each hair follicle, giving you a hairline that looks super natural.
                  <span className="block mt-2 text-[#00B6DE]">مکمل ہیئر لائن: ایف یو ای درستگی کے بارے میں ہے۔ سرجن احتیاط سے ہر ہیئر فولیکل کو رکھتا ہے، جو آپ کو ایک قدرتی نظر آنے والی ہیئر لائن دیتا ہے۔</span>
                </li>
                <li>
                  <strong>Less Drama, Less Stress:</strong> FUE is way less invasive than the older methods. There is no need to remove a whole strip of scalp.
                  <span className="block mt-2 text-[#00B6DE]">کم پریشانی، کم تناؤ: ایف یو ای پرانے طریقوں کے مقابلے میں کہیں کم تکلیف دہ ہے۔</span>
                </li>
                <li>
                  <strong>Target Small Spots:</strong> Not everyone needs a full hair overhaul. Sometimes, it's just about fixing a small bald spot.
                  <span className="block mt-2 text-[#00B6DE]">چھوٹے حصوں کو نشانہ بنانا: ہر کسی کو مکمل بالوں کی تبدیلی کی ضرورت نہیں ہوتی۔</span>
                </li>
              </ol>

              <h2>Take Charge of Your Confidence!</h2>
              <p>
                Let's face it—balding does not mean the end of your style or your self-esteem. With modern FUE hair transplant methods, you can bring back your hair and your confidence, no matter at which stage of hair loss you are dealing with.
                <span className="block mt-2 text-[#00B6DE]">آئیے حقیقت کا سامنا کریں - گنجا پن آپ کے انداز یا آپ کے اعتماد کا خاتمہ نہیں ہے۔ جدید ایف یو ای ہیئر ٹرانسپلانٹ طریقوں کے ساتھ، آپ اپنے بال اور اپنا اعتماد واپس لا سکتے ہیں۔</span>
              </p>
            </div>

            {/* Call to Action */}
            <div className="mt-12 glass-card p-8 rounded-2xl text-center">
              <h2 className="text-3xl font-bold gradient-text mb-4">
                Ready to Transform Your Look?
                <span className="block text-xl mt-2 text-[#00B6DE]">اپنی ظاہری شکل کو تبدیل کرنے کے لیے تیار ہیں؟</span>
              </h2>
              <p className="text-gray-300 mb-8">
                Book your consultation today and take the first step towards a fuller head of hair.
                <span className="block mt-2 text-[#00B6DE]">آج ہی اپنی مشاورت بک کریں اور گھنے بالوں کی طرف پہلا قدم اٹھائیں۔</span>
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <a 
                  href="https://wa.me/923194569401"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary inline-flex items-center justify-center"
                >
                  <MessageCircle className="w-5 h-5 mr-2" />
                  WhatsApp Consultation
                </a>
                <a 
                  href="tel:+924237896975"
                  className="btn-secondary inline-flex items-center justify-center"
                >
                  <Phone className="w-5 h-5 mr-2" />
                  Call: 042-37896975
                </a>
              </div>
            </div>

            {/* Tags */}
            <div className="mt-12 flex flex-wrap gap-4">
              <Link to="/blog/tag/hair-transplant" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Hair Transplant
              </Link>
              <Link to="/blog/tag/balding" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Balding
              </Link>
              <Link to="/blog/tag/fue" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                FUE
              </Link>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}