import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Sparkles, ArrowRight, Shield, Award, UserCheck, MessageCircle, Phone, Clock, Gauge, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CategorySearch } from '../components/CategorySearch';

const treatments = [
  {
    area: "Face & Neck",
    urduArea: "چہرہ اور گردن",
    items: [
      { name: "Upper Lip", price: "Rs. 4,500" },
      { name: "Chin & Jawline", price: "Rs. 5,500" },
      { name: "Full Face", price: "Rs. 7,500" },
      { name: "Ears", price: "Rs. 3,500" },
      { name: "Beardline with neckline", price: "Rs. 6,500" },
      { name: "Beardline without neckline", price: "Rs. 3,500" }
    ]
  },
  {
    area: "Body Areas",
    urduArea: "جسم کے حصے",
    items: [
      { name: "Limbs", price: "Rs. 10,000" },
      { name: "Armpits", price: "Rs. 11,000" },
      { name: "Arms", price: "Rs. 11,000" },
      { name: "Bikini Area", price: "Rs. 11,000" },
      { name: "Shoulders", price: "Rs. 11,000" },
      { name: "Back", price: "Rs. 11,000" }
    ]
  },
  {
    area: "Full Packages",
    urduArea: "مکمل پیکجز",
    items: [
      { name: "Full Body Laser", price: "Rs. 38,500" },
      { name: "Half Body Laser", price: "Rs. 20,000" }
    ]
  }
];

const benefits = [
  {
    title: "Permanent Results",
    urduTitle: "مستقل نتائج",
    description: "Up to 90% permanent hair reduction after full course",
    icon: Shield
  },
  {
    title: "Safe & Painless",
    urduTitle: "محفوظ اور درد سے پاک",
    description: "Advanced cooling system for comfortable treatment",
    icon: Award
  },
  {
    title: "All Skin Types",
    urduTitle: "تمام جلد کی اقسام",
    description: "Safe for all skin types and tones",
    icon: UserCheck
  },
  {
    title: "Quick Treatment",
    urduTitle: "فوری علاج",
    description: "Fast treatment with minimal downtime",
    icon: Clock
  }
];

const features = [
  "Advanced diode laser technology",
  "Built-in skin cooling system",
  "Suitable for all skin types",
  "Treats both fine and coarse hair",
  "Year-round treatment available",
  "Minimal discomfort",
  "Fast treatment sessions",
  "Long-lasting results"
];

export function LaserHairRemoval() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="relative min-h-[60vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 gradient-text">
              Laser Hair Removal
              <span className="block text-3xl mt-4 text-[#00B6DE]">لیزر ہیئر ریموول</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              State-of-the-art laser technology for permanent hair reduction. Our advanced system is safe for all skin types and provides faster, more comfortable treatments with better results.
              <span className="block mt-2 text-lg">مستقل بالوں کی کمی کے لیے جدید لیزر ٹیکنالوجی۔ ہمارا جدید سسٹم تمام جلد کی اقسام کے لیے محفوظ ہے۔</span>
            </p>

            <div className="grid md:grid-cols-4 gap-6 mt-12">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-6"
                >
                  <benefit.icon className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                  <h3 className="text-lg font-semibold text-white">{benefit.title}</h3>
                  <p className="text-[#00B6DE] text-sm mb-2">{benefit.urduTitle}</p>
                  <p className="text-sm text-gray-400">{benefit.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>

      {/* Search Section */}
      <section className="py-12">
        <CategorySearch 
          category="Laser"
          placeholder="Search laser hair removal treatments..."
          urduPlaceholder="لیزر ہیئر ریموول علاج تلاش کریں۔۔۔"
        />
      </section>

      {/* Treatment Areas & Pricing */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold gradient-text mb-4">
              Treatment Areas & Pricing
              <span className="block text-2xl mt-2 text-[#00B6DE]">علاج کے حصے اور قیمتیں</span>
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {treatments.map((area, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-6"
              >
                <h3 className="text-2xl font-bold text-white mb-2">
                  {area.area}
                  <span className="block text-lg text-[#00B6DE]">{area.urduArea}</span>
                </h3>
                
                <div className="space-y-4 mt-6">
                  {area.items.map((item, i) => (
                    <div key={i} className="flex items-center justify-between">
                      <span className="text-gray-300">{item.name}</span>
                      <span className="text-[#00B6DE] font-medium">{item.price}</span>
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold gradient-text mb-4">
              Why Choose Our Laser Hair Removal?
              <span className="block text-2xl mt-2 text-[#00B6DE]">ہمارا لیزر ہیئر ریموول کیوں چنیں؟</span>
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-4 flex items-center gap-3"
              >
                <CheckCircle className="w-5 h-5 text-[#00B6DE] flex-shrink-0" />
                <p className="text-gray-300">{feature}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass-card p-8 rounded-2xl text-center"
          >
            <h2 className="text-3xl font-bold gradient-text mb-4">
              Start Your Hair-Free Journey Today
              <span className="block text-xl mt-2 text-[#00B6DE]">آج ہی اپنا بالوں سے پاک سفر شروع کریں</span>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-8">
              Book your consultation today and take the first step towards permanent hair reduction.
              <span className="block mt-2 text-[#00B6DE]">آج ہی اپنی مشاورت بک کریں اور مستقل بالوں کی کمی کی طرف پہلا قدم اٹھائیں۔</span>
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a 
                href="https://wa.me/923194569401"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary inline-flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                WhatsApp Consultation
              </a>
              <a 
                href="tel:+924237896975"
                className="btn-secondary inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call: 042-37896975
              </a>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}