import React, { useState } from 'react';
import { MediaSection } from '../components/MediaSection';
import { MediaUpload } from '../components/MediaUpload';

export function MediaLibrary() {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const handleUploadComplete = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <main className="pt-20">
      <MediaUpload onUploadComplete={handleUploadComplete} />
      <MediaSection key={refreshTrigger} />
    </main>
  );
}