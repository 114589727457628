import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles, ArrowRight, Shield, Award, UserCheck, MessageCircle, Microscope, Droplets, Sun, Clock, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CategorySearch } from '../components/CategorySearch';

const treatments = [
  {
    id: 'acne',
    title: 'Acne Treatment',
    urduTitle: 'مہاسوں کا علاج',
    description: 'Comprehensive medical treatment for all types of acne',
    urduDescription: 'ہر قسم کے مہاسوں کے لیے جامع طبی علاج',
    image: 'https://images.unsplash.com/photo-1616394584738-fc6e612e71b9?w=800',
    features: [
      "Medical-grade treatments",
      "Customized solutions",
      "Prescription medications",
      "Advanced procedures",
      "Scar prevention",
      "Long-term management"
    ]
  },
  {
    id: 'pigmentation',
    title: 'Pigmentation',
    urduTitle: 'رنگت',
    description: 'Advanced treatments for hyperpigmentation and melasma',
    urduDescription: 'ہائپرپگمینٹیشن اور میلاسما کے لیے جدید علاج',
    image: 'https://images.unsplash.com/photo-1598440947619-2c35fc9aa908?w=800',
    features: [
      "Medical peels",
      "Laser treatments",
      "Topical medications",
      "Sun protection",
      "Maintenance plans",
      "Prevention strategies"
    ]
  },
  {
    id: 'scars',
    title: 'Scar Revision',
    urduTitle: 'زخم کی اصلاح',
    description: 'Professional treatment for various types of scars',
    urduDescription: 'مختلف قسم کے نشانات کے لیے پیشہ ورانہ علاج',
    image: 'https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?w=800',
    features: [
      "Surgical revision",
      "Laser resurfacing",
      "Microneedling",
      "PRP therapy",
      "Topical treatments",
      "Prevention care"
    ]
  },
  {
    id: 'veins',
    title: 'Thread Veins',
    urduTitle: 'نسوں کا علاج',
    description: 'Safe and effective treatment for visible veins',
    urduDescription: 'نظر آنے والی نسوں کے لیے محفوظ اور مؤثر علاج',
    image: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?w=800',
    features: [
      "Laser therapy",
      "Sclerotherapy",
      "Non-invasive options",
      "Quick recovery",
      "Long-lasting results",
      "Preventive care"
    ]
  },
  {
    id: 'moles',
    title: 'Mole Removal',
    urduTitle: 'تل کا علاج',
    description: 'Professional removal of moles and skin tags',
    urduDescription: 'تل اور جلد کے ٹیگز کا پیشہ ورانہ علاج',
    image: 'https://images.unsplash.com/photo-1628191081676-8f40d4ce6c44?w=800',
    features: [
      "Medical assessment",
      "Safe removal",
      "Minimal scarring",
      "Quick procedure",
      "Expert care",
      "Follow-up care"
    ]
  }
];

const benefits = [
  {
    icon: Shield,
    title: 'Medical-Grade Care',
    urduTitle: 'طبی درجے کی دیکھ بھال',
    description: 'All treatments performed by qualified dermatologists'
  },
  {
    icon: Award,
    title: 'Latest Technology',
    urduTitle: 'جدید ٹیکنالوجی',
    description: 'State-of-the-art equipment and products'
  },
  {
    icon: UserCheck,
    title: 'Personalized Plans',
    urduTitle: 'ذاتی منصوبے',
    description: 'Customized treatment plans for your skin'
  },
  {
    icon: Clock,
    title: 'Quick Results',
    urduTitle: 'فوری نتائج',
    description: 'Visible improvements from first session'
  }
];

export function Dermatology() {
  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="relative min-h-[60vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 gradient-text">
              Dermatology Services
              <span className="block text-3xl mt-4 text-[#00B6DE]">ڈرماٹولوجی خدمات</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Expert dermatological care for all your skin concerns
              <span className="block mt-2 text-lg">آپ کی جلد کی تمام تشویشات کے لیے ماہر ڈرماٹولوجی دیکھ بھال</span>
            </p>

            {/* Call to Action Buttons */}
            <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
              <a 
                href="https://wa.me/923194569401"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary inline-flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                WhatsApp Consultation
              </a>
              <a 
                href="tel:+924237896975"
                className="btn-secondary inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call: 042-37896975
              </a>
            </div>

            <div className="grid md:grid-cols-4 gap-6 mt-12">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-6"
                >
                  <benefit.icon className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                  <h3 className="text-lg font-semibold text-white">{benefit.title}</h3>
                  <p className="text-[#00B6DE] text-sm mb-2">{benefit.urduTitle}</p>
                  <p className="text-sm text-gray-400">{benefit.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>

      {/* Add Search Section */}
      <section className="py-12">
        <CategorySearch 
          category="Dermatology"
          placeholder="Search skin conditions, treatments, procedures..."
          urduPlaceholder="جلد کی حالتیں، علاج، طریقہ کار تلاش کریں۔۔۔"
        />
      </section>

      {/* Treatment Sections */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {treatments.map((treatment, index) => (
              <motion.div
                key={treatment.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card overflow-hidden group hover-scale"
              >
                <div className="relative h-48">
                  <img
                    src={treatment.image}
                    alt={treatment.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent" />
                  <div className="absolute bottom-4 left-4 right-4">
                    <h3 className="text-2xl font-bold text-white">{treatment.title}</h3>
                    <p className="text-[#00B6DE]">{treatment.urduTitle}</p>
                  </div>
                </div>

                <div className="p-6">
                  <p className="text-gray-300 mb-2">{treatment.description}</p>
                  <p className="text-[#00B6DE] text-sm mb-4">{treatment.urduDescription}</p>

                  <ul className="space-y-2 mb-6">
                    {treatment.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <Sparkles className="w-4 h-4 text-[#00B6DE] mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>

                  <Link
                    to="/price-lahore-skin-clinic"
                    className="btn-primary w-full text-center inline-flex items-center justify-center"
                  >
                    View Pricing
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass-card p-8 rounded-2xl text-center"
          >
            <h2 className="text-3xl font-bold gradient-text mb-4">
              Contact Us for Expert Dermatology Care
              <span className="block text-xl mt-2 text-[#00B6DE]">ماہر ڈرماٹولوجی دیکھ بھال کے لیے ہم سے رابطہ کریں</span>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-8">
              Get expert advice from our dermatologists for your skin concerns
              <span className="block mt-2 text-[#00B6DE]">اپنی جلد کی تشویشات کے لیے ہمارے ماہرین امراض جلد سے ماہرانہ مشورہ حاصل کریں</span>
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a 
                href="https://wa.me/923194569401"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary inline-flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                WhatsApp Consult
              </a>
              <a 
                href="tel:+924237896975"
                className="btn-secondary inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call Now: 042-37896975
              </a>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}