import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Calendar, Clock, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';

const blogPosts = [
  {
    title: "Balding is Out, Full Head Of Hair Is In: Men, Listen Up!",
    urduTitle: "گنجا پن ختم، گھنے بال موجود: مردوں، توجہ دیں!",
    excerpt: "Discover how modern FUE hair transplant methods can help you regain your confidence and a full head of hair.",
    image: "https://images.unsplash.com/photo-1626954079979-ec4f7b05e032?w=800",
    date: "October 11, 2024",
    readTime: "8 min read",
    category: "Hair Care",
    slug: "/blog/balding-treatment-hair-transplant"
  },
  {
    title: "The Science Behind Chemical Peels",
    urduTitle: "کیمیکل پیلز کے پیچھے سائنس",
    excerpt: "Discover how chemical peels work to reveal fresher, younger-looking skin and why they're a go-to treatment for various skin concerns.",
    image: "https://images.unsplash.com/photo-1598440947619-2c35fc9aa908?w=800",
    date: "March 10, 2024",
    readTime: "4 min read",
    category: "Skin Care",
    slug: "/blog/science-behind-chemical-peels"
  },
  {
    title: "Everything You Need to Know About Dermal Fillers",
    urduTitle: "ڈرمل فلرز کے بارے میں آپ کو جو جاننے کی ضرورت ہے",
    excerpt: "Learn about different types of dermal fillers, how they work, and what to expect from the treatment process.",
    image: "https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?w=800",
    date: "March 5, 2024",
    readTime: "6 min read",
    category: "Aesthetics",
    slug: "/blog/guide-to-dermal-fillers"
  }
];

export function BlogCarousel() {
  return (
    <section className="py-20 bg-gray-900/50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold gradient-text mb-4">
            Latest from Our Blog
            <span className="block text-2xl mt-2 text-[#00B6DE]">ہمارے بلاگ سے تازہ ترین</span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Expert insights on beauty, skin care, and wellness
            <span className="block mt-2 text-lg text-[#00B6DE]">خوبصورتی، جلد کی دیکھ بھال، اور تندرستی پر ماہرانہ بصیرت</span>
          </p>
        </div>

        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          className="pb-12"
        >
          {blogPosts.map((post, index) => (
            <SwiperSlide key={index}>
              <Link to={post.slug} className="block group">
                <div className="glass-card overflow-hidden rounded-xl h-full">
                  <div className="relative aspect-[4/3]">
                    <img
                      src={post.image}
                      alt={post.title}
                      className="absolute inset-0 w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
                    <div className="absolute bottom-4 left-4 right-4">
                      <span className="px-3 py-1 bg-[#00B6DE]/20 text-[#00B6DE] rounded-full text-sm">
                        {post.category}
                      </span>
                    </div>
                  </div>
                  <div className="p-6">
                    <div className="flex items-center gap-4 mb-4 text-sm text-gray-400">
                      <div className="flex items-center">
                        <Calendar className="w-4 h-4 mr-1" />
                        {post.date}
                      </div>
                      <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-1" />
                        {post.readTime}
                      </div>
                    </div>
                    <h3 className="text-xl font-bold text-white mb-2 group-hover:text-[#00B6DE] transition-colors">
                      {post.title}
                    </h3>
                    <p className="text-[#00B6DE] text-sm mb-4">{post.urduTitle}</p>
                    <p className="text-gray-300 text-sm line-clamp-2 mb-4">{post.excerpt}</p>
                    <div className="inline-flex items-center text-[#00B6DE] group-hover:translate-x-2 transition-transform">
                      <span>Read More</span>
                      <ArrowRight className="w-4 h-4 ml-2" />
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="text-center mt-8">
          <Link 
            to="/blog"
            className="btn-primary inline-flex items-center"
          >
            View All Articles
            <ArrowRight className="w-4 h-4 ml-2" />
          </Link>
        </div>
      </div>
    </section>
  );
}