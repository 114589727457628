import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Loader2, RefreshCw, Clock, Globe, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface WebhookData {
  id: string;
  payload: any;
  source: string;
  status: string;
  timestamp: string;
  created_at: string;
}

export function WebhookPage() {
  const [data, setData] = useState<WebhookData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  async function fetchWebhookData() {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from('webhook_data')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setData(data || []);
    } catch (error) {
      console.error('Error fetching webhook data:', error);
      setError('Failed to fetch webhook data');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchWebhookData();
    
    // Set up real-time subscription
    const subscription = supabase
      .channel('webhook_changes')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'webhook_data'
        },
        (payload) => {
          setData(currentData => [payload.new as WebhookData, ...currentData]);
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 pt-20">
      <div className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <div className="flex items-center justify-between">
            <h1 className="text-4xl font-bold text-white">
              Webhook Data
              <span className="block text-2xl mt-2 text-emerald-400">ویب ہک ڈیٹا</span>
            </h1>
            <button
              onClick={fetchWebhookData}
              disabled={loading}
              className="btn-primary flex items-center"
            >
              {loading ? (
                <Loader2 className="w-5 h-5 animate-spin mr-2" />
              ) : (
                <RefreshCw className="w-5 h-5 mr-2" />
              )}
              Refresh
            </button>
          </div>

          <div className="mt-4 glass-card p-6">
            <h2 className="text-xl font-semibold text-white mb-4">Webhook URL</h2>
            <div className="bg-gray-800 p-4 rounded-lg">
              <code className="text-emerald-400">
                {`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/webhook`}
              </code>
            </div>
            <p className="mt-4 text-gray-300 text-sm">
              Use this URL in your ScraperAPI.com webhook configuration
            </p>
          </div>
        </motion.div>

        {error && (
          <div className="glass-card p-4 mb-8 text-red-500 flex items-center">
            <AlertCircle className="w-5 h-5 mr-2" />
            {error}
          </div>
        )}

        <div className="grid gap-6">
          {data.map((item) => (
            <motion.div
              key={item.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="glass-card p-6"
            >
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center">
                  <Globe className="w-5 h-5 text-emerald-400 mr-2" />
                  <span className="text-white font-semibold">
                    {item.source}
                  </span>
                  <span className={`ml-3 px-2 py-1 rounded-full text-xs ${
                    item.status === 'success' ? 'bg-green-500/20 text-green-400' :
                    item.status === 'error' ? 'bg-red-500/20 text-red-400' :
                    'bg-gray-500/20 text-gray-400'
                  }`}>
                    {item.status}
                  </span>
                </div>
                <div className="flex items-center text-gray-400 text-sm">
                  <Clock className="w-4 h-4 mr-1" />
                  {new Date(item.created_at).toLocaleString()}
                </div>
              </div>

              <div className="bg-gray-800 rounded-lg p-4 overflow-x-auto">
                <pre className="text-sm text-gray-300">
                  {JSON.stringify(item.payload, null, 2)}
                </pre>
              </div>
            </motion.div>
          ))}
        </div>

        {loading && data.length === 0 && (
          <div className="flex justify-center py-12">
            <Loader2 className="w-8 h-8 text-emerald-400 animate-spin" />
          </div>
        )}

        {!loading && data.length === 0 && (
          <div className="text-center py-12 text-gray-400">
            No webhook data received yet
          </div>
        )}
      </div>
    </div>
  );
}