import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Globe } from 'lucide-react';

interface SearchResult {
  id: string;
  content: string;
  metadata: {
    title: string;
    category: string;
    source?: string;
  };
  similarity: number;
}

interface SearchResultsProps {
  results: SearchResult[];
  loading?: boolean;
  error?: string | null;
}

export function SearchResults({ results, loading, error }: SearchResultsProps) {
  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-500/10 text-red-500 rounded-lg p-4">
        {error}
      </div>
    );
  }

  if (!results.length) {
    return (
      <div className="text-center py-8 text-gray-400">
        No results found
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {results.map((result) => (
        <motion.div
          key={result.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="glass-card p-6"
        >
          <div className="flex items-start justify-between mb-4">
            <div>
              <h3 className="text-lg font-semibold text-white mb-1">
                {result.metadata.title}
              </h3>
              <div className="flex items-center gap-2">
                <span className="text-sm px-2 py-1 bg-emerald-500/20 text-emerald-400 rounded-full">
                  {result.metadata.category}
                </span>
                <span className="text-sm text-gray-400">
                  {Math.round(result.similarity * 100)}% match
                </span>
              </div>
            </div>
            {result.metadata.source && (
              <a
                href={result.metadata.source}
                target="_blank"
                rel="noopener noreferrer"
                className="text-emerald-400 hover:text-emerald-300 flex items-center gap-1"
              >
                <Globe className="w-4 h-4" />
                <span className="text-sm">Source</span>
              </a>
            )}
          </div>
          
          <div className="prose prose-invert max-w-none">
            <p className="text-gray-300">{result.content}</p>
          </div>
        </motion.div>
      ))}
    </div>
  );
}