import React, { useState, useRef, useEffect } from 'react';
import { Mic, MicOff, Timer } from 'lucide-react';

interface VoiceSearchProps {
  onSearch: (query: string) => void;
}

const MAX_RECORDING_TIME = 15; // 15 seconds

export function VoiceSearch({ onSearch }: VoiceSearchProps) {
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState(MAX_RECORDING_TIME);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const transcriptRef = useRef<string>('');

  useEffect(() => {
    if (isListening) {
      // Start timer countdown
      timerRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            stopListening();
            return MAX_RECORDING_TIME;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isListening]);

  const startListening = () => {
    try {
      setError(null);
      setTimeLeft(MAX_RECORDING_TIME);
      transcriptRef.current = '';

      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = 'ur-PK'; // Set to Urdu by default

      recognition.onresult = (event) => {
        let interimTranscript = '';
        
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            transcriptRef.current += transcript + ' ';
          } else {
            interimTranscript += transcript;
          }
        }

        // Update search with interim results
        if (interimTranscript) {
          onSearch(interimTranscript);
        }
      };

      recognition.onerror = (event) => {
        setError('Error recognizing speech');
        console.error('Speech recognition error:', event.error);
        stopListening();
      };

      recognition.onend = () => {
        if (transcriptRef.current) {
          onSearch(transcriptRef.current.trim());
        }
        stopListening();
      };

      recognition.start();
      setIsListening(true);
      recognitionRef.current = recognition;
    } catch (error) {
      setError('Speech recognition not supported');
      console.error('Speech recognition error:', error);
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setIsListening(false);
    setTimeLeft(MAX_RECORDING_TIME);
  };

  return (
    <div className="relative">
      <button
        onClick={isListening ? stopListening : startListening}
        className={`p-2 rounded-full transition-colors ${
          isListening 
            ? 'bg-red-500/20 text-red-500' 
            : 'text-emerald-400 hover:bg-emerald-500/20'
        } relative`}
        title={isListening ? 'Stop recording' : 'Start voice search'}
      >
        {isListening ? (
          <>
            <MicOff className="w-5 h-5" />
            <div className="absolute -top-8 left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded flex items-center gap-1">
              <Timer className="w-3 h-3" />
              <span>{timeLeft}s</span>
            </div>
          </>
        ) : (
          <Mic className="w-5 h-5" />
        )}
      </button>
      {error && (
        <div className="absolute top-full left-0 right-0 mt-2 text-red-500 text-sm flex items-center">
          {error}
        </div>
      )}
    </div>
  );
}

// Add TypeScript support for Web Speech API
declare global {
  interface Window {
    SpeechRecognition: typeof SpeechRecognition;
    webkitSpeechRecognition: typeof SpeechRecognition;
  }
}