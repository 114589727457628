import React, { useState } from 'react';
import { HeroSection } from '../components/HeroSection';
import { ServicesSection } from '../components/ServicesSection';
import { ReviewSlider } from '../components/ReviewSlider';
import { BlogCarousel } from '../components/BlogCarousel';

interface HomePageProps {
  onChatOpen: () => void;
}

export function HomePage({ onChatOpen }: HomePageProps) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    if (searchQuery.trim()) {
      onChatOpen();
    }
  };

  return (
    <main>
      <HeroSection 
        searchQuery={searchQuery} 
        setSearchQuery={setSearchQuery}
        onSearch={handleSearch}
        onChatOpen={onChatOpen}
      />
      <ServicesSection />
      <BlogCarousel />
      <div className="relative z-10">
        <div className="container mx-auto px-4 mt-20 mb-32">
          <div className="glass-card p-8 rounded-2xl">
            <h2 className="text-3xl font-bold text-center mb-8 gradient-text">
              What Our Clients Say
              <span className="block text-xl mt-2 text-[#00B6DE]">ہمارے کلائنٹس کیا کہتے ہیں</span>
            </h2>
            <ReviewSlider />
          </div>
        </div>
      </div>
    </main>
  );
}