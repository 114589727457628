import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, ArrowRight, Search, Tag, MessageCircle, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';

const featuredPost = {
  title: "Balding is Out, Full Head Of Hair Is In: Men, Listen Up!",
  urduTitle: "گنجا پن ختم، گھنے بال موجود: مردوں، توجہ دیں!",
  excerpt: "Discover how modern FUE hair transplant methods can help you regain your confidence and a full head of hair. Learn about the latest techniques, recovery process, and what makes FUE the clear winner in hair restoration.",
  image: "https://images.unsplash.com/photo-1626954079979-ec4f7b05e032?w=800",
  date: "October 11, 2024",
  readTime: "8 min read",
  category: "Hair Care",
  slug: "/blog/balding-treatment-hair-transplant"
};

const recentPosts = [
  {
    title: "Understanding Hair Loss: Causes, Types, and Solutions",
    urduTitle: "بالوں کا گرنا: وجوہات، اقسام، اور حل",
    excerpt: "Hair loss can be a distressing experience, affecting both men and women. Learn about the different types of hair loss, their causes, and the most effective treatments available at Glow & Go.",
    image: "https://images.unsplash.com/photo-1626954079979-ec4f7b05e032?w=800",
    date: "March 15, 2024",
    readTime: "5 min read",
    category: "Hair Care",
    slug: "/blog/understanding-hair-loss"
  },
  {
    title: "The Science Behind Chemical Peels",
    urduTitle: "کیمیکل پیلز کے پیچھے سائنس",
    excerpt: "Discover how chemical peels work to reveal fresher, younger-looking skin and why they're a go-to treatment for various skin concerns.",
    image: "https://images.unsplash.com/photo-1598440947619-2c35fc9aa908?w=800",
    date: "March 10, 2024",
    readTime: "4 min read",
    category: "Skin Care",
    slug: "/blog/science-behind-chemical-peels"
  },
  {
    title: "Everything You Need to Know About Dermal Fillers",
    urduTitle: "ڈرمل فلرز کے بارے میں آپ کو جو جاننے کی ضرورت ہے",
    excerpt: "Learn about different types of dermal fillers, how they work, and what to expect from the treatment process.",
    image: "https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?w=800",
    date: "March 5, 2024",
    readTime: "6 min read",
    category: "Aesthetics",
    slug: "/blog/guide-to-dermal-fillers"
  }
];

const categories = [
  "Skin Care",
  "Hair Care",
  "Aesthetics",
  "Treatments",
  "Beauty Tips",
  "Wellness"
];

export function BlogPage() {
  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="relative min-h-[40vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 gradient-text">
              Glow & Go Blog
              <span className="block text-3xl mt-4 text-[#00B6DE]">گلو اینڈ گو بلاگ</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Expert insights on skin care, beauty treatments, and wellness
              <span className="block mt-2 text-lg">جلد کی دیکھ بھال، خوبصورتی کے علاج، اور تندرستی پر ماہرانہ بصیرت</span>
            </p>
          </div>
        </motion.div>
      </div>

      {/* Search Section */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-2xl mx-auto">
            <div className="relative">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search articles..."
                className="w-full pl-12 pr-4 py-4 bg-gray-800/50 border border-gray-700 rounded-full text-white focus:ring-2 focus:ring-[#00B6DE] focus:border-transparent"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Featured Post */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass-card overflow-hidden rounded-2xl"
          >
            <Link to={featuredPost.slug} className="block group">
              <div className="relative aspect-[21/9]">
                <img
                  src={featuredPost.image}
                  alt={featuredPost.title}
                  className="absolute inset-0 w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
              </div>
              <div className="p-8">
                <div className="flex items-center gap-4 mb-4">
                  <span className="px-3 py-1 bg-[#00B6DE]/20 text-[#00B6DE] rounded-full text-sm">
                    {featuredPost.category}
                  </span>
                  <div className="flex items-center text-gray-400 text-sm">
                    <Calendar className="w-4 h-4 mr-1" />
                    {featuredPost.date}
                  </div>
                  <div className="flex items-center text-gray-400 text-sm">
                    <Clock className="w-4 h-4 mr-1" />
                    {featuredPost.readTime}
                  </div>
                </div>
                <h2 className="text-3xl font-bold text-white mb-2 group-hover:text-[#00B6DE] transition-colors">
                  {featuredPost.title}
                </h2>
                <p className="text-[#00B6DE] mb-4">{featuredPost.urduTitle}</p>
                <p className="text-gray-300 mb-6">{featuredPost.excerpt}</p>
                <div className="inline-flex items-center text-[#00B6DE] group-hover:translate-x-2 transition-transform">
                  <span>Read More</span>
                  <ArrowRight className="w-4 h-4 ml-2" />
                </div>
              </div>
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Recent Posts */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-3xl font-bold gradient-text mb-8"
          >
            Recent Articles
            <span className="block text-xl mt-2 text-[#00B6DE]">حالیہ مضامین</span>
          </motion.h2>

          <div className="grid md:grid-cols-3 gap-8">
            {recentPosts.map((post, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card overflow-hidden rounded-xl group"
              >
                <Link to={post.slug}>
                  <div className="relative aspect-[4/3]">
                    <img
                      src={post.image}
                      alt={post.title}
                      className="absolute inset-0 w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
                  </div>
                  <div className="p-6">
                    <div className="flex items-center gap-4 mb-4">
                      <span className="px-3 py-1 bg-[#00B6DE]/20 text-[#00B6DE] rounded-full text-sm">
                        {post.category}
                      </span>
                      <div className="flex items-center text-gray-400 text-sm">
                        <Clock className="w-4 h-4 mr-1" />
                        {post.readTime}
                      </div>
                    </div>
                    <h3 className="text-xl font-bold text-white mb-2 group-hover:text-[#00B6DE] transition-colors">
                      {post.title}
                    </h3>
                    <p className="text-[#00B6DE] text-sm mb-4">{post.urduTitle}</p>
                    <p className="text-gray-300 text-sm line-clamp-3">{post.excerpt}</p>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Categories */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-3xl font-bold gradient-text mb-8"
          >
            Categories
            <span className="block text-xl mt-2 text-[#00B6DE]">زمرہ جات</span>
          </motion.h2>

          <div className="flex flex-wrap gap-4">
            {categories.map((category, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link
                  to={`/blog/category/${category.toLowerCase().replace(' ', '-')}`}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors"
                >
                  <Tag className="w-4 h-4" />
                  {category}
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter */}
      <section className="py-20 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass-card p-8 rounded-2xl text-center"
          >
            <h2 className="text-3xl font-bold gradient-text mb-4">
              Stay Updated
              <span className="block text-xl mt-2 text-[#00B6DE]">اپ ڈیٹ رہیں</span>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-8">
              Subscribe to our newsletter for the latest beauty tips and treatment updates
              <span className="block mt-2 text-[#00B6DE]">تازہ ترین خوبصورتی کی تجاویز اور علاج کی تازہ کاریوں کے لیے ہمارے نیوز لیٹر کی رکنیت حاصل کریں</span>
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a 
                href="https://wa.me/923194569401"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary inline-flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                Join on WhatsApp
              </a>
              <a 
                href="tel:+924237896975"
                className="btn-secondary inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call: 042-37896975
              </a>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}