import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Search, AlertCircle, Upload, Loader2, FileText, Trash2, Download } from 'lucide-react';
import { supabase, getStorageUrl, ensureBucketExists } from '../lib/supabase';
import { FileUpload } from '../components/FileUpload';
import { SearchResults } from '../components/SearchResults';
import { semanticSearch, generateSearchResponse } from '../lib/search';
import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

interface StorageFile {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  last_accessed_at: string;
  metadata: {
    size: number;
    mimetype: string;
  };
  url?: string;
}

const BUCKET_NAME = 'documents';
const MAX_CHUNK_SIZE = 4000; // Characters per chunk, roughly ~1000 tokens

function chunkText(text: string): string[] {
  const paragraphs = text.split(/\n\s*\n/);
  const chunks: string[] = [];
  let currentChunk = '';

  for (const paragraph of paragraphs) {
    if ((currentChunk + paragraph).length > MAX_CHUNK_SIZE && currentChunk) {
      chunks.push(currentChunk.trim());
      currentChunk = '';
    }
    
    if (paragraph.length > MAX_CHUNK_SIZE) {
      const sentences = paragraph.match(/[^.!?]+[.!?]+/g) || [paragraph];
      for (const sentence of sentences) {
        if ((currentChunk + sentence).length > MAX_CHUNK_SIZE && currentChunk) {
          chunks.push(currentChunk.trim());
          currentChunk = '';
        }
        currentChunk += sentence + ' ';
      }
    } else {
      currentChunk += paragraph + '\n\n';
    }
  }

  if (currentChunk.trim()) {
    chunks.push(currentChunk.trim());
  }

  return chunks;
}

export function DocumentsPage() {
  const [files, setFiles] = useState<StorageFile[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [aiResponse, setAiResponse] = useState<string | null>(null);
  const [searching, setSearching] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkAuth();
    initializeStorage();
  }, []);

  async function checkAuth() {
    const { data: { session } } = await supabase.auth.getSession();
    setIsAuthenticated(!!session);
  }

  async function initializeStorage() {
    try {
      await ensureBucketExists(BUCKET_NAME);
      await fetchFiles();
    } catch (err) {
      console.error('Error initializing storage:', err);
      setError('Failed to initialize storage');
    } finally {
      setLoading(false);
    }
  }

  async function fetchFiles() {
    try {
      const { data: storageFiles, error: listError } = await supabase
        .storage
        .from(BUCKET_NAME)
        .list();

      if (listError) throw listError;

      const filesWithUrls = (storageFiles || []).map((file) => ({
        ...file,
        url: getStorageUrl(BUCKET_NAME, file.name)
      }));

      setFiles(filesWithUrls);
    } catch (err) {
      console.error('Error fetching files:', err);
      setError('Failed to fetch files');
    }
  }

  const processChunk = async (chunk: string, index: number, total: number) => {
    try {
      const embedding = await openai.embeddings.create({
        model: 'text-embedding-3-small',
        input: chunk,
      });

      const { error: insertError } = await supabase.auth.getSession().then(({ data: { session } }) => {
        if (!session) throw new Error('Authentication required');
        
        return supabase
          .from('documents')
          .insert({
            content: chunk,
            embedding: embedding.data[0].embedding,
            metadata: {
              title: `Content Part ${index + 1}/${total}`,
              category: 'General',
              source: 'File Upload'
            }
          });
      });

      if (insertError) throw insertError;
    } catch (error) {
      console.error('Error processing chunk:', error);
      throw error;
    }
  };

  const handleFileSelect = async (file: File) => {
    if (!isAuthenticated) {
      setError('Please sign in to upload files');
      return;
    }

    try {
      setUploading(true);
      setError(null);
      setProgress({ current: 0, total: 0 });

      // Upload file to storage
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}-${file.name}`;

      const { error: uploadError } = await supabase
        .storage
        .from(BUCKET_NAME)
        .upload(uniqueFileName, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      // Process file content
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          const content = e.target?.result as string;
          let textToProcess = '';
          
          if (file.name.endsWith('.jsonl')) {
            const lines = content.split('\n').filter(Boolean);
            for (const line of lines) {
              try {
                const data = JSON.parse(line.trim());
                textToProcess += (typeof data === 'string' ? data : 
                               data.content || data.text || 
                               JSON.stringify(data)) + '\n\n';
              } catch (parseError) {
                console.warn('Skipping invalid JSONL line:', line);
                continue;
              }
            }
          } else if (file.name.endsWith('.json')) {
            try {
              const data = JSON.parse(content);
              textToProcess = typeof data === 'string' ? data :
                            Array.isArray(data) ? data.map(item => 
                              typeof item === 'string' ? item :
                              item.content || item.text || 
                              JSON.stringify(item)
                            ).join('\n\n') :
                            data.content || data.text || 
                            JSON.stringify(data);
            } catch (parseError) {
              throw new Error('Invalid JSON format');
            }
          } else {
            textToProcess = content;
          }

          const chunks = chunkText(textToProcess);
          setProgress({ current: 0, total: chunks.length });

          for (let i = 0; i < chunks.length; i++) {
            await processChunk(chunks[i], i, chunks.length);
            setProgress({ current: i + 1, total: chunks.length });
          }
          
          await fetchFiles();
          alert('File processed successfully!');
        } catch (error) {
          console.error('Error processing file:', error);
          setError(`Failed to process file: ${error.message}`);
        }
      };

      reader.onerror = () => {
        setError('Failed to read file');
      };

      reader.readAsText(file);
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload file');
    } finally {
      setUploading(false);
      setProgress({ current: 0, total: 0 });
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      setSearching(true);
      setError(null);
      setAiResponse(null);

      const results = await semanticSearch(searchQuery, {
        matchThreshold: 0.5,
        matchCount: 5
      });

      if (!results.length) {
        setAiResponse("No matching information found in our database.\n\nہمارے ڈیٹا بیس میں کوئی مماثل معلومات نہیں ملیں۔");
        setSearchResults([]);
        return;
      }

      setSearchResults(results);

      const response = await generateSearchResponse(searchQuery, results);
      setAiResponse(response);
    } catch (err) {
      console.error('Search error:', err);
      setError('Failed to perform search');
    } finally {
      setSearching(false);
    }
  };

  async function handleDelete(fileName: string) {
    try {
      const { error: deleteError } = await supabase
        .storage
        .from(BUCKET_NAME)
        .remove([fileName]);

      if (deleteError) throw deleteError;
      await fetchFiles();
    } catch (err) {
      console.error('Error deleting file:', err);
      setError('Failed to delete file');
    }
  }

  function formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-20">
      <div className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <h1 className="text-4xl font-bold text-white">
            Document Management & Search
            <span className="block text-2xl mt-2 text-emerald-400">دستاویز مینجمنٹ اور تلاش</span>
          </h1>
        </motion.div>

        {/* File Upload */}
        <div className="glass-card p-6 mb-8">
          <h2 className="text-xl font-bold text-white mb-4">
            Upload Documents
            <span className="block text-sm mt-1 text-emerald-400">دستاویزات اپ لوڈ کریں</span>
          </h2>
          
          {!isAuthenticated && (
            <div className="bg-yellow-500/10 border border-yellow-500 text-yellow-500 rounded-lg p-4 mb-4">
              Please sign in to upload files
            </div>
          )}
          
          <FileUpload
            onFileSelect={handleFileSelect}
            loading={uploading}
            accept=".txt,.json,.jsonl"
          />
          
          {uploading && (
            <div className="mt-4">
              <div className="flex items-center justify-center text-emerald-400 mb-2">
                <Loader2 className="w-5 h-5 animate-spin mr-2" />
                Processing file... ({progress.current}/{progress.total})
              </div>
              {progress.total > 0 && (
                <div className="w-full bg-gray-800 rounded-full h-2">
                  <div 
                    className="bg-emerald-500 h-2 rounded-full transition-all duration-300"
                    style={{ width: `${(progress.current / progress.total) * 100}%` }}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {/* Search Form */}
        <div className="glass-card p-6 mb-8">
          <h2 className="text-xl font-bold text-white mb-4">
            Search Documents
            <span className="block text-sm mt-1 text-emerald-400">دستاویزات تلاش کریں</span>
          </h2>

          <form onSubmit={handleSearch} className="space-y-4">
            <div className="relative">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search documents..."
                className="w-full pl-12 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              />
            </div>

            <div className="flex gap-4">
              <button
                type="submit"
                disabled={searching || !searchQuery.trim()}
                className="btn-primary flex-1"
              >
                {searching ? 'Searching...' : 'Search'}
              </button>
            </div>
          </form>
        </div>

        {error && (
          <div className="bg-red-500/10 border border-red-500 text-red-500 rounded-lg p-4 mb-8 flex items-center">
            <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
            {error}
          </div>
        )}

        {/* AI Response */}
        {aiResponse && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="glass-card p-6 mb-8"
          >
            <h2 className="text-xl font-bold text-white mb-4">AI Response</h2>
            <div className="prose prose-invert max-w-none">
              <p className="whitespace-pre-wrap">{aiResponse}</p>
            </div>
          </motion.div>
        )}

        {/* Search Results */}
        {searchResults.length > 0 && (
          <div className="mb-8">
            <SearchResults
              results={searchResults}
              loading={searching}
              error={error}
            />
          </div>
        )}

        {/* Files Grid */}
        <div className="glass-card p-6">
          <h2 className="text-xl font-bold text-white mb-4">
            Uploaded Files
            <span className="block text-sm mt-1 text-emerald-400">اپ لوڈ کردہ فائلیں</span>
          </h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {files.map((file) => (
              <motion.div
                key={file.name}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="glass-card p-6"
              >
                <div className="flex items-start justify-between mb-4">
                  <div className="flex-1 mr-4">
                    <h3 className="text-lg font-semibold text-white truncate">
                      {file.name}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {formatFileSize(file.metadata.size)}
                    </p>
                    <p className="text-xs text-gray-500">
                      Uploaded: {new Date(file.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <a
                      href={file.url}
                      download
                      className="p-2 text-emerald-400 hover:bg-emerald-500/20 rounded-lg transition-colors"
                    >
                      <Download className="w-5 h-5" />
                    </a>
                    <button
                      onClick={() => handleDelete(file.name)}
                      className="p-2 text-red-400 hover:bg-red-500/20 rounded-lg transition-colors"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {loading && files.length === 0 && (
            <div className="flex justify-center py-12">
              <Loader2 className="w-8 h-8 text-emerald-400 animate-spin" />
            </div>
          )}

          {!loading && files.length === 0 && (
            <div className="text-center py-12 text-gray-400">
              No files uploaded yet
            </div>
          )}
        </div>
      </div>
    </div>
  );
}