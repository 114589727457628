import React, { useCallback } from 'react';
import { Upload, FileText, AlertCircle } from 'lucide-react';

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  accept?: string;
  loading?: boolean;
}

export function FileUpload({ onFileSelect, accept = ".json,.jsonl,.csv,.html,.txt", loading = false }: FileUploadProps) {
  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      onFileSelect(file);
    }
  }, [onFileSelect]);

  const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }
  }, [onFileSelect]);

  return (
    <div
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      className="glass-card p-8 border-2 border-dashed border-gray-700 rounded-xl cursor-pointer hover:border-emerald-500 transition-colors"
    >
      <input
        type="file"
        accept={accept}
        onChange={handleFileInput}
        className="hidden"
        id="file-upload"
        disabled={loading}
      />
      <label htmlFor="file-upload" className="flex flex-col items-center cursor-pointer">
        <Upload className="w-12 h-12 text-emerald-400 mb-4" />
        <div className="text-center">
          <p className="text-lg font-semibold text-white mb-2">
            Drop your file here or click to upload
          </p>
          <p className="text-sm text-gray-400">
            Supports JSON, JSONL, CSV, and HTML files
          </p>
        </div>
      </label>
    </div>
  );
}