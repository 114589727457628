import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Search, AlertCircle, Upload, Loader2 } from 'lucide-react';
import { SearchResults } from '../components/SearchResults';
import { semanticSearch, generateSearchResponse } from '../lib/search';
import { supabase } from '../lib/supabase';
import { FileUpload } from '../components/FileUpload';
import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const BUCKET_NAME = 'documents';
const MAX_CHUNK_SIZE = 4000; // Characters per chunk, roughly ~1000 tokens

function chunkText(text: string): string[] {
  // Split by paragraphs first
  const paragraphs = text.split(/\n\s*\n/);
  const chunks: string[] = [];
  let currentChunk = '';

  for (const paragraph of paragraphs) {
    // If adding this paragraph would exceed the chunk size, save current chunk and start new one
    if ((currentChunk + paragraph).length > MAX_CHUNK_SIZE && currentChunk) {
      chunks.push(currentChunk.trim());
      currentChunk = '';
    }
    
    // If a single paragraph is longer than max size, split it by sentences
    if (paragraph.length > MAX_CHUNK_SIZE) {
      const sentences = paragraph.match(/[^.!?]+[.!?]+/g) || [paragraph];
      for (const sentence of sentences) {
        if ((currentChunk + sentence).length > MAX_CHUNK_SIZE && currentChunk) {
          chunks.push(currentChunk.trim());
          currentChunk = '';
        }
        currentChunk += sentence + ' ';
      }
    } else {
      currentChunk += paragraph + '\n\n';
    }
  }

  // Add the last chunk if it's not empty
  if (currentChunk.trim()) {
    chunks.push(currentChunk.trim());
  }

  return chunks;
}

export function TextPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState([]);
  const [aiResponse, setAiResponse] = useState<string | null>(null);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    const { data: { session } } = await supabase.auth.getSession();
    setIsAuthenticated(!!session);
  }

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      setLoading(true);
      setError(null);
      setAiResponse(null);

      const searchResults = await semanticSearch(searchQuery, {
        matchThreshold: 0.5,
        matchCount: 5,
        category: selectedCategory
      });

      if (!searchResults.length) {
        setAiResponse("No matching information found in our database.\n\nہمارے ڈیٹا بیس میں کوئی مماثل معلومات نہیں ملیں۔");
        setResults([]);
        return;
      }

      setResults(searchResults);

      const response = await generateSearchResponse(searchQuery, searchResults);
      setAiResponse(response);
    } catch (err) {
      console.error('Search error:', err);
      setError('Failed to perform search');
    } finally {
      setLoading(false);
    }
  };

  const processChunk = async (chunk: string, index: number, total: number) => {
    try {
      // Generate embedding for the chunk
      const embedding = await openai.embeddings.create({
        model: 'text-embedding-3-small',
        input: chunk,
      });

      // Store in database using service role client
      const { error: insertError } = await supabase.auth.getSession().then(({ data: { session } }) => {
        if (!session) throw new Error('Authentication required');
        
        return supabase
          .from('documents')
          .insert({
            content: chunk,
            embedding: embedding.data[0].embedding,
            metadata: {
              title: `Content Part ${index + 1}/${total}`,
              category: 'General',
              source: 'File Upload'
            }
          });
      });

      if (insertError) throw insertError;
    } catch (error) {
      console.error('Error processing chunk:', error);
      throw error;
    }
  };

  const handleFileSelect = async (file: File) => {
    if (!isAuthenticated) {
      setError('Please sign in to upload files');
      return;
    }

    try {
      setUploading(true);
      setError(null);
      setProgress({ current: 0, total: 0 });

      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          const content = e.target?.result as string;
          let textToProcess = '';
          
          if (file.name.endsWith('.jsonl')) {
            const lines = content.split('\n').filter(Boolean);
            for (const line of lines) {
              try {
                const data = JSON.parse(line.trim());
                textToProcess += (typeof data === 'string' ? data : 
                               data.content || data.text || 
                               JSON.stringify(data)) + '\n\n';
              } catch (parseError) {
                console.warn('Skipping invalid JSONL line:', line);
                continue;
              }
            }
          } else if (file.name.endsWith('.json')) {
            try {
              const data = JSON.parse(content);
              textToProcess = typeof data === 'string' ? data :
                            Array.isArray(data) ? data.map(item => 
                              typeof item === 'string' ? item :
                              item.content || item.text || 
                              JSON.stringify(item)
                            ).join('\n\n') :
                            data.content || data.text || 
                            JSON.stringify(data);
            } catch (parseError) {
              throw new Error('Invalid JSON format');
            }
          } else {
            textToProcess = content;
          }

          // Split content into chunks
          const chunks = chunkText(textToProcess);
          setProgress({ current: 0, total: chunks.length });

          // Process each chunk
          for (let i = 0; i < chunks.length; i++) {
            await processChunk(chunks[i], i, chunks.length);
            setProgress({ current: i + 1, total: chunks.length });
          }
          
          alert('File processed successfully!');
        } catch (error) {
          console.error('Error processing file:', error);
          setError(`Failed to process file: ${error.message}`);
        }
      };

      reader.onerror = () => {
        setError('Failed to read file');
      };

      reader.readAsText(file);
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload file');
    } finally {
      setUploading(false);
      setProgress({ current: 0, total: 0 });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 pt-20">
      <div className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <h1 className="text-4xl font-bold text-white">
            Text Content Search
            <span className="block text-2xl mt-2 text-emerald-400">متن مواد کی تلاش</span>
          </h1>
        </motion.div>

        {/* File Upload */}
        <div className="glass-card p-6 mb-8">
          <h2 className="text-xl font-bold text-white mb-4">
            Upload Content
            <span className="block text-sm mt-1 text-emerald-400">مواد اپ لوڈ کریں</span>
          </h2>
          
          {!isAuthenticated && (
            <div className="bg-yellow-500/10 border border-yellow-500 text-yellow-500 rounded-lg p-4 mb-4">
              Please sign in to upload files
            </div>
          )}
          
          <FileUpload
            onFileSelect={handleFileSelect}
            loading={uploading}
            accept=".txt,.json,.jsonl"
          />
          
          {uploading && (
            <div className="mt-4">
              <div className="flex items-center justify-center text-emerald-400 mb-2">
                <Loader2 className="w-5 h-5 animate-spin mr-2" />
                Processing file... ({progress.current}/{progress.total})
              </div>
              {progress.total > 0 && (
                <div className="w-full bg-gray-800 rounded-full h-2">
                  <div 
                    className="bg-emerald-500 h-2 rounded-full transition-all duration-300"
                    style={{ width: `${(progress.current / progress.total) * 100}%` }}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {/* Search Form */}
        <div className="glass-card p-6 mb-8">
          <form onSubmit={handleSearch} className="space-y-4">
            <div className="relative">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search content..."
                className="w-full pl-12 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              />
            </div>

            <div className="flex gap-4">
              <button
                type="submit"
                disabled={loading || !searchQuery.trim()}
                className="btn-primary flex-1"
              >
                {loading ? 'Searching...' : 'Search'}
              </button>
            </div>
          </form>
        </div>

        {error && (
          <div className="bg-red-500/10 border border-red-500 text-red-500 rounded-lg p-4 mb-8 flex items-center">
            <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
            {error}
          </div>
        )}

        {aiResponse && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="glass-card p-6 mb-8"
          >
            <h2 className="text-xl font-bold text-white mb-4">AI Response</h2>
            <div className="prose prose-invert max-w-none">
              <p className="whitespace-pre-wrap">{aiResponse}</p>
            </div>
          </motion.div>
        )}

        <SearchResults
          results={results}
          loading={loading}
          error={error}
        />
      </div>
    </div>
  );
}