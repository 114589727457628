import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, MessageCircle, Phone, Shield, Award, UserCheck, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const categories = [
  {
    title: "Consultation Services",
    urduTitle: "مشاورت کی خدمات",
    items: [
      { name: "Aesthetic Consultation", price: "Complimentary" },
      { name: "Dermatological Consultation", price: "2,000" }
    ]
  },
  {
    title: "Skin Cleansing",
    urduTitle: "جلد کی صفائی",
    items: [
      { name: "Dermaplaning", price: "5,000" },
      { name: "Microdermabrasion", price: "5,000" },
      { name: "Medical Facial (AHAs/BHAs)", price: "5,500" },
      { name: "Glow Hydra (includes microdermabrasion)", price: "10,000" },
      { name: "Glow Hydra Ultra+ (includes skin peel)", price: "14,000" },
      { name: "White Head Treatment", price: "12,000" }
    ]
  },
  {
    title: "Chemical Peels",
    urduTitle: "کیمیکل پیلز",
    items: [
      { name: "Peel To Reveal Plus", price: "8,000", note: "Price on consultation" },
      { name: "Peel To Reveal Intense", price: "10,000", note: "Price on consultation" }
    ]
  },
  {
    title: "Micro Needling",
    urduTitle: "مائیکرو نیڈلنگ",
    items: [
      { name: "Micro Needling + PRP", price: "18,000" },
      { name: "Micro Needling + SB", price: "20,000" },
      { name: "Micro Needling PRP + SB", price: "25,000" },
      { name: "Micro RF Micro Needling", price: "30,000" },
      { name: "BB Glow with Pigment Only", price: "18,000" },
      { name: "BB Glow with Pigment + Serum", price: "20,000" }
    ]
  },
  {
    title: "Mesotherapy",
    urduTitle: "میزوتھراپی",
    items: [
      { name: "Lightening Mesotherapy", price: "12,500" },
      { name: "Mesotherapy (PRP + SB)", price: "15,000" }
    ]
  },
  {
    title: "Deep Skin Treatments",
    urduTitle: "گہری جلد کے علاج",
    items: [
      { name: "HIFU Jawline (double chin)", price: "25,000" },
      { name: "HIFU Full Face", price: "40,000" },
      { name: "HIFU Full Neck", price: "25,000" },
      { name: "HIFU Half face + Fillers (HD jawline)", price: "95,000" }
    ]
  },
  {
    title: "Vitamin Drips",
    urduTitle: "وٹامن ڈرپس",
    items: [
      { name: "Glow Skin Drip", price: "15,000" },
      { name: "Boost Vitamin Drip", price: "15,000" },
      { name: "Immune Boost (plus vitamin b12)", price: "15,000" },
      { name: "Energy Boost", price: "15,000" },
      { name: "Slimming Drip", price: "15,000" }
    ]
  },
  {
    title: "Semi Permanent Makeup",
    urduTitle: "سیمی پرمننٹ میک اپ",
    items: [
      { name: "Lip Tint", price: "35,000" },
      { name: "Eye Brows", price: "35,000" }
    ]
  },
  {
    title: "Genital Rejuvenation",
    urduTitle: "جینیٹل ریجوینیشن",
    items: [
      { name: "O Shot", price: "10,000" },
      { name: "P Shot", price: "10,000" }
    ]
  },
  {
    title: "Laser Hair Removal",
    urduTitle: "لیزر ہیئر ریموول",
    items: [
      { name: "Upper Lip", price: "4,500" },
      { name: "Chin & Jawline", price: "5,500" },
      { name: "Full Face", price: "7,500" },
      { name: "Limbs", price: "10,000" },
      { name: "Armpits, Arms, Bikini, Shoulders, Back", price: "11,000", note: "per Area" },
      { name: "Ears", price: "3,500" },
      { name: "Beardline with neckline", price: "6,500" },
      { name: "Beardline without neckline", price: "3,500" },
      { name: "Full Body Laser", price: "38,500" },
      { name: "Half Body Laser", price: "20,000" },
      { name: "Face Shave", price: "2,000" },
      { name: "Body Shave", price: "5,000" }
    ]
  },
  {
    title: "Botox Treatments",
    urduTitle: "بوٹاکس علاج",
    note: "Prices may vary according to needs",
    items: [
      { name: "Baby Botox / Crows Feet", price: "35,000" },
      { name: "Upper Face / Forehead Lines", price: "45,000" },
      { name: "Excessive Sweating (Per Area underarms / palms)", price: "38,500" },
      { name: "Excessive Sweating Forehead", price: "35,500" },
      { name: "Nefertiti Lift", price: "38,500" },
      { name: "Wide Jaw Reduction / Bruxism TMJ", price: "38,500" },
      { name: "Migraine / Headache", price: "45,000" },
      { name: "Frown Lines", price: "35,000" }
    ]
  },
  {
    title: "Dermal Fillers",
    urduTitle: "ڈرمل فلرز",
    note: "Prices per 1ml, may vary according to needs",
    items: [
      { name: "Facial Lines & Wrinkles / ML (frown)", price: "35,000" },
      { name: "Lips / ML", price: "38,500" },
      { name: "Temple / ML", price: "35,000" },
      { name: "Radiesse Filler / ML", price: "90,000" },
      { name: "Bio Filler / ML", price: "27,500" },
      { name: "Chin, Cheeks, Jawline Filler / ML", price: "38,500" },
      { name: "Under eye Circles / ML", price: "45,000" },
      { name: "Non Surgical Nose Job", price: "55,000" },
      { name: "Filler Dissolving", price: "10,000" },
      { name: "Hand Filler", price: "35,000" },
      { name: "Fat Dissolving", price: "25,000" },
      { name: "Ellanse Filler", price: "60,500", note: "per ml – 121,000/2ml" },
      { name: "Profhilo", price: "105,000" },
      { name: "Stem Cell Therapy Injections", price: "250,000" }
    ]
  },
  {
    title: "PDO Thread Treatments",
    urduTitle: "پی ڈی او تھریڈ علاج",
    note: "Prices may vary according to needs",
    items: [
      { name: "Smooth Threads / Area / Side", price: "55,000", note: "from" },
      { name: "Lifting Threads (PDO) Full Face", price: "125,000" },
      { name: "Lifting Threads (PDO) Per Pair", price: "30,000" },
      { name: "Lifting Threads (PCL) Per Pair", price: "50,000" },
      { name: "Full Face Lifting + Dermal Fillers (3ml)", price: "175,000", note: "from" },
      { name: "APTOS Threads", price: "125,000" },
      { name: "Hair Loss Threads", price: "145,000" }
    ]
  },
  {
    title: "Minor Procedures",
    urduTitle: "معمولی طریقہ کار",
    items: [
      { name: "Consultation Fee", price: "2,000" },
      { name: "Mole Removal / Per Area", price: "5,000" },
      { name: "Skin Tag Removal / Per Area", price: "5,000" },
      { name: "Alopecia", price: "5,000" }
    ]
  },
  {
    title: "Hair Treatments",
    urduTitle: "بالوں کے علاج",
    items: [
      { name: "Consultation Fee", price: "1,500" },
      { name: "Hair PRP", price: "15,000", note: "Price on consultation" },
      { name: "Hair PRP + Hair Booster", price: "20,000" },
      { name: "Exosomes Therapy", price: "70,000" },
      { name: "Hair Transplant (FUE/BHT)", price: "Custom Price" },
      { name: "Steam Cell Injection", price: "250,000" }
    ]
  }
];

export function PricingPage() {
  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="relative min-h-[40vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 gradient-text">
              Treatment Prices
              <span className="block text-3xl mt-4 text-[#00B6DE]">علاج کی قیمتیں</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Making Quality Services Accessible To You
              <span className="block mt-2 text-lg">معیاری خدمات کو آپ تک رسائی فراہم کرنا</span>
            </p>

            <div className="grid md:grid-cols-3 gap-6 mt-12">
              <div className="glass-card p-6">
                <Shield className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Premium Care</h3>
                <p className="text-sm text-gray-400">Medical-grade treatments</p>
              </div>
              <div className="glass-card p-6">
                <Award className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Expert Doctors</h3>
                <p className="text-sm text-gray-400">UK-trained specialists</p>
              </div>
              <div className="glass-card p-6">
                <UserCheck className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Membership Plans</h3>
                <p className="text-sm text-gray-400">Special package rates</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Price List */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid gap-8">
            {categories.map((category, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-6 rounded-2xl"
              >
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h2 className="text-2xl font-bold text-white">{category.title}</h2>
                    <p className="text-[#00B6DE]">{category.urduTitle}</p>
                  </div>
                  {category.note && (
                    <div className="flex items-center text-gray-400 text-sm">
                      <Info className="w-4 h-4 mr-1" />
                      {category.note}
                    </div>
                  )}
                </div>

                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {category.items.map((item, i) => (
                    <div key={i} className="glass-card p-4">
                      <div className="flex items-start justify-between">
                        <div className="flex-1">
                          <h3 className="text-white font-medium">{item.name}</h3>
                          {item.note && (
                            <p className="text-gray-400 text-sm">{item.note}</p>
                          )}
                        </div>
                        <span className="text-emerald-400 font-medium whitespace-nowrap ml-4">
                          {item.price.toLowerCase() === 'custom price' ? (
                            'POC*'
                          ) : (
                            `Rs. ${item.price}`
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>

          <div className="mt-8 text-gray-400 text-sm">
            <p>* Price on Consultation (POC)</p>
            <p>Above prices are exclusive of taxes</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass-card p-8 rounded-2xl text-center"
          >
            <h2 className="text-3xl font-bold gradient-text mb-4">
              Book Your Consultation Today
              <span className="block text-xl mt-2 text-[#00B6DE]">آج ہی اپنی مشاورت بک کریں</span>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-8">
              Let's start your journey to better skin. Our experts will create a personalized treatment plan for you.
              <span className="block mt-2 text-[#00B6DE]">آئیے بہتر جلد کی طرف آپ کا سفر شروع کریں۔ ہمارے ماہرین آپ کے لیے ذاتی علاج کا منصوبہ بنائیں گے۔</span>
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link 
                to="https://wa.me/923194569401"
                className="btn-primary inline-flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                WhatsApp Consultation
              </Link>
              <a 
                href="tel:+924237896975"
                className="btn-secondary inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call: 042-37896975
              </a>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}