import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { Menu, Phone, MapPin, Mail, X, ArrowRight } from 'lucide-react';
import { Logo } from './components/Logo';
import { HomePage } from './pages/HomePage';
import { MediaLibrary } from './pages/MediaLibrary';
import { TextPage } from './pages/TextPage';
import { WebhookPage } from './pages/WebhookPage';
import { DocumentsPage } from './pages/DocumentsPage';
import { MedSpaPage } from './pages/MedSpaPage';
import { InjectableTreatments } from './pages/InjectableTreatments';
import { Dermatology } from './pages/Dermatology';
import { PricingPage } from './pages/PricingPage';
import { HairTransplant } from './pages/HairTransplant';
import { HairLoss } from './pages/HairLoss';
import { BlogPage } from './pages/BlogPage';
import { ChatBot, ChatBotHandle } from './components/ChatBot';
import { BaldingTreatment } from './pages/BlogPost/BaldingTreatment';
import { ChemicalPeels } from './pages/BlogPost/ChemicalPeels';
import { DermalFillers } from './pages/BlogPost/DermalFillers';
import { LaserHairRemoval } from './pages/LaserHairRemoval';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const chatBotRef = useRef<ChatBotHandle>(null);

  const handleChatOpen = () => {
    if (chatBotRef.current) {
      chatBotRef.current.openChat();
    }
  };

  const menuSections = [
    {
      title: "Treatments",
      urduTitle: "علاج",
      color: "from-[#00B6DE] to-[#0095B7]",
      items: [
        {
          title: "Laser Hair Removal",
          urduTitle: "لیزر ہیئر ریموول",
          description: "Permanent hair reduction with advanced laser technology",
          link: "/laser-hair-removal"
        },
        {
          title: "MedSpa Treatments",
          urduTitle: "میڈسپا علاج",
          description: "Advanced facial treatments and skin rejuvenation",
          link: "/medspa-treatments"
        },
        {
          title: "Injectable Treatments",
          urduTitle: "انجکشن والے علاج",
          description: "Botox and dermal fillers for natural enhancement",
          link: "/injectable-treatments"
        }
      ]
    },
    {
      title: "Hair Solutions",
      urduTitle: "بالوں کے حل",
      color: "from-purple-500 to-pink-500",
      items: [
        {
          title: "Hair Transplant",
          urduTitle: "بال ٹرانسپلانٹ",
          description: "Advanced FUE and DHI techniques",
          link: "/hair-transplant"
        },
        {
          title: "Hair Loss Treatment",
          urduTitle: "بالوں کے گرنے کا علاج",
          description: "Comprehensive solutions for all types of hair loss",
          link: "/hair-loss"
        }
      ]
    },
    {
      title: "Resources",
      urduTitle: "وسائل",
      color: "from-emerald-500 to-teal-500",
      items: [
        {
          title: "Blog & Articles",
          urduTitle: "بلاگ اور مضامین",
          description: "Expert insights on beauty and wellness",
          link: "/blog"
        },
        {
          title: "Treatment Prices",
          urduTitle: "علاج کی قیمتیں",
          description: "Comprehensive price list for all services",
          link: "/price-lahore-skin-clinic"
        }
      ]
    }
  ];

  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-black text-white">
        {/* Header */}
        <header className="fixed w-full z-[100] bg-black/80 backdrop-blur-lg border-b border-gray-800">
          <div className="container mx-auto">
            <div className="flex items-center justify-between h-16 md:h-20 px-4">
              {/* Menu Button */}
              <button 
                className="text-gray-300 hover:text-white p-2"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </button>

              {/* Centered Logo */}
              <Link to="/" className="absolute left-1/2 -translate-x-1/2">
                <Logo />
              </Link>

              {/* WhatsApp Button */}
              <a 
                href="https://wa.me/923194569401" 
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary hidden md:flex items-center text-sm py-2"
              >
                <svg 
                  viewBox="0 0 24 24" 
                  className="w-4 h-4 mr-2 fill-current"
                >
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
                </svg>
                WhatsApp
              </a>
              
              {/* Mobile WhatsApp Icon */}
              <a 
                href="https://wa.me/923194569401" 
                target="_blank"
                rel="noopener noreferrer"
                className="md:hidden p-2 text-[#00B6DE]"
              >
                <svg 
                  viewBox="0 0 24 24" 
                  className="w-6 h-6 fill-current"
                >
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
                </svg>
              </a>
            </div>
          </div>
        </header>

        {/* Full Screen Menu */}
        <div className={`fixed inset-0 bg-black/95 backdrop-blur-lg z-[90] transition-all duration-300 ${isMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
          <div className="container mx-auto p-4 pt-24">
            <div className="grid gap-4">
              {menuSections.map((section, index) => (
                <div key={index} className="space-y-3">
                  <h2 className={`text-xl md:text-2xl font-bold bg-gradient-to-r ${section.color} bg-clip-text text-transparent`}>
                    {section.title}
                    <span className="block text-base md:text-lg mt-0.5">{section.urduTitle}</span>
                  </h2>
                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {section.items.map((item, i) => (
                      <div key={i} className="glass-card p-4 hover:bg-gray-800/50 transition-colors">
                        <h3 className="text-base md:text-lg font-bold text-white mb-1">{item.title}</h3>
                        <p className="text-[#00B6DE] text-sm mb-2">{item.urduTitle}</p>
                        <p className="text-gray-400 text-sm mb-3">{item.description}</p>
                        <Link
                          to={item.link}
                          onClick={() => setIsMenuOpen(false)}
                          className="inline-flex items-center text-[#00B6DE] hover:text-white transition-colors text-sm"
                        >
                          <span>More Information</span>
                          <ArrowRight className="w-4 h-4 ml-1" />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              {/* Contact Information */}
              <div className="glass-card p-4 mt-2">
                <h3 className="text-lg font-bold text-white mb-3">Contact Us</h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                  <a href="tel:+924237896975" className="flex items-center text-gray-300 hover:text-[#00B6DE]">
                    <Phone className="w-4 h-4 mr-2" />
                    042-37896975
                  </a>
                  <a href="tel:+924237896977" className="flex items-center text-gray-300 hover:text-[#00B6DE]">
                    <Phone className="w-4 h-4 mr-2" />
                    042-37896977
                  </a>
                  <a href="mailto:info@glowandgo.pk" className="flex items-center text-gray-300 hover:text-[#00B6DE]">
                    <Mail className="w-4 h-4 mr-2" />
                    info@glowandgo.pk
                  </a>
                  <div className="flex items-center text-gray-300">
                    <MapPin className="w-4 h-4 mr-2 flex-shrink-0" />
                    <span className="text-sm">27-K, Sir Syed Road, Block-K, Gulberg-II, Lahore</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <Routes>
          <Route path="/" element={<HomePage onChatOpen={handleChatOpen} />} />
          <Route path="/medspa-treatments" element={<MedSpaPage />} />
          <Route path="/injectable-treatments" element={<InjectableTreatments />} />
          <Route path="/skin-rejuvenation-dermatology-lahore" element={<Dermatology />} />
          <Route path="/price-lahore-skin-clinic" element={<PricingPage />} />
          <Route path="/hair-transplant" element={<HairTransplant />} />
          <Route path="/hair-loss" element={<HairLoss />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/balding-treatment-hair-transplant" element={<BaldingTreatment />} />
          <Route path="/blog/science-behind-chemical-peels" element={<ChemicalPeels />} />
          <Route path="/blog/guide-to-dermal-fillers" element={<DermalFillers />} />
          <Route path="/laser-hair-removal" element={<LaserHairRemoval />} />
          <Route path="/media" element={<MediaLibrary />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/text" element={<TextPage />} />
          <Route path="/webhook" element={<WebhookPage />} />
        </Routes>

        {/* Chat Bot */}
        <ChatBot ref={chatBotRef} />
      </div>
    </Router>
  );
}

export default App;