import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Sparkles, ArrowRight, Droplets, FlaskRound as Flask, Zap, Syringe, Microscope, Gauge } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { Link } from 'react-router-dom';
import { CategorySearch } from '../components/CategorySearch';

const treatments = [
  {
    id: 'hydrafacial',
    icon: Droplets,
    name: 'HydraFacial',
    urduName: 'ہائیڈرا فیشل',
    price: 'Rs. 10,000 - 14,000',
    description: 'Our signature HydraFacial combines deep cleansing, exfoliation, and hydration in one revolutionary treatment. Using patented technology, it removes dead skin cells, extracts impurities, and bathes the skin with cleansing, hydrating and moisturizing serums.',
    urduDescription: 'ہمارا سگنیچر ہائیڈرا فیشل گہری صفائی، ایکسفولیئیشن، اور ہائیڈریشن کو ایک انقلابی علاج میں یکجا کرتا ہے۔',
    image: 'https://images.unsplash.com/photo-1570172619644-dfd03ed5d881?w=800',
    demoImage: '/hydrafacial-demo.jpg',
    benefits: [
      'Deep cleansing and pore extraction',
      'Instant hydration and glow',
      'No downtime',
      'Suitable for all skin types'
    ],
    steps: [
      'Cleanse + Peel: Uncover a new layer of skin with gentle exfoliation',
      'Extract + Hydrate: Remove debris from pores & nourish with moisturizers',
      'Fuse + Protect: Saturate skin surface with antioxidants and peptides',
      'Extend + Protect: Maintain results with daily protection'
    ],
    variants: [
      {
        name: 'Glow Hydra',
        price: 'Rs. 10,000',
        includes: ['Microdermabrasion', 'Deep cleansing', 'Hydration']
      },
      {
        name: 'Glow Hydra Ultra+',
        price: 'Rs. 14,000',
        includes: ['Everything in Glow Hydra', 'Additional skin peel', 'Enhanced results']
      }
    ]
  },
  {
    id: 'prescription-skincare',
    icon: Flask,
    name: 'Prescription Skincare',
    urduName: 'نسخہ جاتی اسکن کیئر',
    price: 'Starting from Rs. 5,000',
    description: 'Medical-grade skincare products prescribed by our dermatologists to address your specific skin concerns. These products contain active ingredients at therapeutic concentrations to improve cell turnover, reduce pigmentation, and enhance overall skin health.',
    urduDescription: 'ہمارے ماہرین امراض جلد کی طرف سے تجویز کردہ طبی درجے کی اسکن کیئر مصنوعات آپ کی خاص جلد کی تشویشات کو دور کرنے کے لیے۔',
    benefits: [
      'Customized for your skin type',
      'Medical-grade ingredients',
      'Scientifically proven results',
      'Progressive improvement'
    ]
  },
  {
    id: 'chemical-peels',
    icon: Zap,
    name: 'Chemical Peels',
    urduName: 'کیمیکل پیلز',
    price: 'Rs. 8,000 - 10,000',
    description: 'Advanced chemical peels that multiply the effects of a normal facial by 100x in just 10 minutes. These treatments accelerate cell turnover, improve texture, reduce pigmentation, and stimulate collagen production.',
    urduDescription: 'ایڈوانسڈ کیمیکل پیلز جو معمولی فیشل کے اثرات کو محض 10 منٹ میں 100 گنا بڑھا دیتے ہیں۔',
    benefits: [
      'Rapid results',
      'Improved skin texture',
      'Reduced pigmentation',
      'Collagen stimulation'
    ],
    variants: [
      {
        name: 'Peel To Reveal Plus',
        price: 'Rs. 8,000',
        includes: ['Gentle exfoliation', 'Brightening']
      },
      {
        name: 'Peel To Reveal Intense',
        price: 'Rs. 10,000',
        includes: ['Deep exfoliation', 'Advanced brightening', 'Anti-aging']
      }
    ]
  },
  {
    id: 'microneedling',
    icon: Microscope,
    name: 'Advanced Microneedling',
    urduName: 'ایڈوانسڈ مائیکرونیڈلنگ',
    price: 'Rs. 18,000 - 30,000',
    description: 'A deep skin treatment that uses fine needles to create controlled micro-injuries, stimulating natural collagen production. Enhanced with PRP, exosomes, or polynucleotides for maximum skin regeneration.',
    urduDescription: 'ایک گہرا جلد کا علاج جو باریک سوئیوں کا استعمال کرتے ہوئے کنٹرول شدہ مائیکرو انجریز پیدا کرتا ہے۔',
    benefits: [
      'Improves acne scarring',
      'Thickens thinning skin',
      'Stimulates collagen',
      'Natural skin regeneration'
    ],
    variants: [
      {
        name: 'Microneedling + PRP',
        price: 'Rs. 18,000',
        includes: ['Basic microneedling', 'PRP treatment']
      },
      {
        name: 'Microneedling + PRP + SB',
        price: 'Rs. 25,000',
        includes: ['Enhanced healing', 'Better results']
      },
      {
        name: 'RF Microneedling',
        price: 'Rs. 30,000',
        includes: ['Radio frequency', 'Deeper skin remodeling']
      }
    ]
  }
];

const otherCategories = [
  {
    name: "Laser Hair Removal",
    urduName: "لیزر ہیئر ریموول",
    description: "Permanent hair reduction using state-of-the-art laser technology. Safe for all skin types.",
    urduDescription: "جدید لیزر ٹیکنالوجی کا استعمال کرتے ہوئے مستقل بالوں کی کمی۔ تمام جلد کی اقسام کے لیے محفوظ۔",
    icon: Gauge,
    link: "/medspa-treatments",
    image: "https://images.unsplash.com/photo-1598256989800-fe5f95da9787?w=800",
    features: [
      "Latest technology",
      "All skin types",
      "Full body packages",
      "Fast treatments"
    ]
  },
  {
    name: "Injectable Treatments",
    urduName: "انجکشن والے علاج",
    description: "Premium dermal fillers and muscle relaxants for natural-looking enhancement.",
    urduDescription: "قدرتی نظر آنے والے اضافے کے لیے پریمیم ڈرمل فلرز اور مسل ریلیکسنٹس۔",
    icon: Syringe,
    link: "/injectable-treatments",
    image: "https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?w=800",
    features: [
      "Natural results",
      "Premium products",
      "Expert doctors",
      "Custom plans"
    ]
  },
  {
    name: "Skin Treatments",
    urduName: "جلد کے علاج",
    description: "Advanced solutions for acne, pigmentation, aging, and other skin concerns.",
    urduDescription: "مہاسوں، پگمینٹیشن، عمر رسیدگی اور دیگر جلد کی تشویشات کے لیے جدید حل۔",
    icon: Flask,
    link: "/medspa-treatments",
    image: "https://images.unsplash.com/photo-1616394584738-fc6e612e71b9?w=800",
    features: [
      "Medical grade",
      "Custom solutions",
      "Latest technology",
      "All skin types"
    ]
  }
];

export function MedSpaPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: { publicUrl } } = supabase.storage
    .from('videos')
    .getPublicUrl('0.4446247676169883.MP4');

  return (
    <>
      <Helmet>
        <title>MedSpa Treatments in Lahore - Facials, Peels & More | Glow & Go</title>
        <meta name="description" content="Experience luxury MedSpa treatments in Lahore. HydraFacial, chemical peels, microneedling, and more advanced skin treatments by expert dermatologists at Glow & Go." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.glowandgo.pk/medspa-treatments" />
        <meta property="og:title" content="MedSpa Treatments in Lahore - Facials, Peels & More | Glow & Go" />
        <meta property="og:description" content="Experience luxury MedSpa treatments in Lahore. HydraFacial, chemical peels, microneedling, and more advanced skin treatments by expert dermatologists at Glow & Go." />
        <meta property="og:image" content="https://images.unsplash.com/photo-1570172619644-dfd03ed5d881?w=1200" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.glowandgo.pk/medspa-treatments" />
        <meta name="twitter:title" content="MedSpa Treatments in Lahore - Facials, Peels & More | Glow & Go" />
        <meta name="twitter:description" content="Experience luxury MedSpa treatments in Lahore. HydraFacial, chemical peels, microneedling, and more advanced skin treatments by expert dermatologists at Glow & Go." />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1570172619644-dfd03ed5d881?w=1200" />
      </Helmet>

      <div className="min-h-screen bg-black">
        {/* Video Banner */}
        <div className="relative h-[60vh] overflow-hidden">
          <div className="absolute inset-0 bg-black/60 z-10"></div>
          <video
            src={publicUrl}
            autoPlay
            loop
            muted
            playsInline
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black z-20"></div>
          
          <div className="relative z-30 container mx-auto px-4 h-full flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold gradient-text mb-4">
                MedSpa Treatments
                <span className="block text-2xl mt-2 text-[#00B6DE]">میڈسپا علاج</span>
              </h1>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Experience our advanced aesthetic treatments, combining medical expertise with luxury spa comfort
                <span className="block mt-2 text-lg">ہمارے جدید جمالیاتی علاج، طبی مہارت کو لگژری اسپا کی آسائش کے ساتھ ملاتے ہیں</span>
              </p>
            </motion.div>
          </div>
        </div>

        {/* Add Search Section */}
        <section className="py-12">
          <CategorySearch 
            category="MedSpa"
            placeholder="Search MedSpa treatments, facials, peels..."
            urduPlaceholder="میڈسپا علاج، فیشلز، پیلز تلاش کریں۔۔۔"
          />
        </section>

        {/* Treatments Content */}
        <div className="container mx-auto px-4 py-12">
          <div className="space-y-16">
            {treatments.map((treatment, index) => (
              <motion.div
                key={treatment.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-8 rounded-2xl"
              >
                <div className="flex flex-col lg:flex-row gap-8">
                  <div className="lg:w-2/3">
                    <div className="flex items-center gap-4 mb-6">
                      <div className="w-12 h-12 rounded-full bg-[#00B6DE]/20 flex items-center justify-center">
                        <treatment.icon className="w-6 h-6 text-[#00B6DE]" />
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold text-white">{treatment.name}</h2>
                        <p className="text-[#00B6DE]">{treatment.urduName}</p>
                      </div>
                    </div>

                    {treatment.id === 'hydrafacial' && (
                      <div className="grid md:grid-cols-2 gap-6 mb-8">
                        <div className="relative aspect-video rounded-xl overflow-hidden">
                          <img 
                            src={treatment.image} 
                            alt="HydraFacial Treatment"
                            className="absolute inset-0 w-full h-full object-cover"
                          />
                        </div>
                        <div className="relative aspect-video rounded-xl overflow-hidden">
                          <img 
                            src="https://images.unsplash.com/photo-1616394584738-fc6e612e71b9?w=800"
                            alt="HydraFacial Results"
                            className="absolute inset-0 w-full h-full object-cover"
                          />
                        </div>
                      </div>
                    )}

                    <div className="prose prose-invert max-w-none mb-6">
                      <p className="text-gray-300">{treatment.description}</p>
                      <p className="text-gray-400 text-sm">{treatment.urduDescription}</p>
                    </div>

                    {treatment.id === 'hydrafacial' && (
                      <div className="mb-8">
                        <h3 className="text-xl font-semibold text-white mb-4">Treatment Steps</h3>
                        <div className="grid md:grid-cols-2 gap-4">
                          {treatment.steps.map((step, i) => (
                            <div key={i} className="flex items-start gap-3 glass-card p-4">
                              <div className="w-8 h-8 rounded-full bg-[#00B6DE]/20 flex items-center justify-center flex-shrink-0">
                                <span className="text-[#00B6DE] font-semibold">{i + 1}</span>
                              </div>
                              <p className="text-gray-300">{step}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="grid md:grid-cols-2 gap-4 mb-6">
                      {treatment.benefits.map((benefit, i) => (
                        <div key={i} className="flex items-center gap-2">
                          <Sparkles className="w-4 h-4 text-[#00B6DE]" />
                          <span className="text-gray-300">{benefit}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="lg:w-1/3">
                    <div className="glass-card p-6 sticky top-24">
                      <div className="text-2xl font-bold text-white mb-4">
                        {treatment.price}
                      </div>
                      {treatment.variants && (
                        <div className="space-y-4">
                          {treatment.variants.map((variant, i) => (
                            <div key={i} className="border-t border-gray-800 pt-4">
                              <h3 className="font-semibold text-white mb-2">{variant.name}</h3>
                              <p className="text-[#00B6DE] mb-2">{variant.price}</p>
                              <ul className="text-sm text-gray-400 space-y-1">
                                {variant.includes.map((item, j) => (
                                  <li key={j} className="flex items-center gap-2">
                                    <ArrowRight className="w-3 h-3" />
                                    {item}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}
                      <a
                        href="https://wa.me/923194569401"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-primary w-full text-center mt-6 flex items-center justify-center gap-2"
                      >
                        <span>Book Consultation</span>
                        <ArrowRight className="w-4 h-4" />
                      </a>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Other Treatment Categories */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="mt-20"
          >
            <h2 className="text-3xl font-bold text-center gradient-text mb-12">
              Explore Other Treatment Categories
              <span className="block text-xl mt-2 text-[#00B6DE]">دیگر علاج کی اقسام دریافت کریں</span>
            </h2>

            <div className="grid md:grid-cols-3 gap-8">
              {otherCategories.map((category, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card overflow-hidden group hover-scale"
                >
                  <div className="relative h-48">
                    <img
                      src={category.image}
                      alt={category.name}
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent" />
                    <div className="absolute bottom-4 left-4 right-4">
                      <div className="flex items-center gap-2 text-white mb-2">
                        <category.icon className="w-5 h-5" />
                        <h3 className="text-lg font-bold">{category.name}</h3>
                      </div>
                      <p className="text-[#00B6DE] text-sm">{category.urduName}</p>
                    </div>
                  </div>

                  <div className="p-6">
                    <p className="text-gray-300 mb-4">{category.description}</p>
                    <p className="text-[#00B6DE] text-sm mb-4">{category.urduDescription}</p>

                    <ul className="space-y-2 mb-6">
                      {category.features.map((feature, i) => (
                        <li key={i} className="flex items-center text-gray-300">
                          <Sparkles className="w-4 h-4 text-[#00B6DE] mr-2" />
                          {feature}
                        </li>
                      ))}
                    </ul>

                    <Link
                      to={category.link}
                      className="btn-primary w-full text-center inline-flex items-center justify-center"
                    >
                      <span>Learn More</span>
                      <ArrowRight className="w-4 h-4 ml-2" />
                    </Link>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}