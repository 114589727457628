import React, { useState } from 'react';
import { Upload, X, Image as ImageIcon, Video, Loader2 } from 'lucide-react';
import { supabase, validateFileSize } from '../lib/supabase';

interface MediaUploadProps {
  onUploadComplete: () => void;
}

export function MediaUpload({ onUploadComplete }: MediaUploadProps) {
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState<{ url: string; type: 'image' | 'video' } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;

      // Validate file size (100MB limit)
      validateFileSize(file);

      // Show preview
      const objectUrl = URL.createObjectURL(file);
      setPreview({
        url: objectUrl,
        type: file.type.startsWith('image/') ? 'image' : 'video'
      });

      setUploading(true);
      setError(null);
      setUploadProgress(0);

      // Generate unique filename
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;

      // Upload to storage with progress tracking
      const { data: storageData, error: storageError } = await supabase.storage
        .from('videos')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false,
          onUploadProgress: (progress) => {
            const percentage = (progress.loaded / progress.total) * 100;
            setUploadProgress(Math.round(percentage));
          }
        });

      if (storageError) throw storageError;

      // Create metadata record
      const { error: dbError } = await supabase
        .from('videos')
        .insert({
          name: fileName,
          storage_object_id: storageData.id
        });

      if (dbError) throw dbError;

      onUploadComplete();
    } catch (err) {
      console.error('Error uploading file:', err);
      setError(err instanceof Error ? err.message : 'Failed to upload file');
      handleRemovePreview();
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  };

  const handleRemovePreview = () => {
    if (preview) {
      URL.revokeObjectURL(preview.url);
      setPreview(null);
    }
  };

  return (
    <div className="glass-card p-6 mb-8">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-bold text-white">
          Upload Media
          <span className="block text-sm mt-1 text-emerald-400">میڈیا اپ لوڈ کریں</span>
        </h3>
      </div>

      {preview ? (
        <div className="relative rounded-lg overflow-hidden">
          {preview.type === 'image' ? (
            <img 
              src={preview.url} 
              alt="Upload preview" 
              className="w-full h-64 object-cover"
            />
          ) : (
            <video 
              src={preview.url}
              className="w-full h-64 object-cover"
              controls
            />
          )}
          
          {uploading ? (
            <div className="absolute inset-0 bg-black/75 flex flex-col items-center justify-center">
              <Loader2 className="w-8 h-8 text-emerald-400 animate-spin mb-2" />
              <p className="text-emerald-400 font-medium">{uploadProgress}%</p>
            </div>
          ) : (
            <button
              onClick={handleRemovePreview}
              className="absolute top-2 right-2 p-2 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
            >
              <X className="w-4 h-4 text-white" />
            </button>
          )}
        </div>
      ) : (
        <label className="block w-full aspect-video border-2 border-dashed border-gray-700 rounded-lg hover:border-emerald-500 transition-colors cursor-pointer">
          <input
            type="file"
            accept="image/*,video/*"
            onChange={handleFileSelect}
            disabled={uploading}
            className="hidden"
          />
          <div className="h-full flex flex-col items-center justify-center">
            {uploading ? (
              <div className="flex flex-col items-center">
                <Loader2 className="w-8 h-8 text-emerald-400 animate-spin mb-2" />
                <p className="text-emerald-400">Uploading... {uploadProgress}%</p>
              </div>
            ) : (
              <>
                <div className="flex items-center gap-4 mb-4">
                  <ImageIcon className="w-8 h-8 text-gray-400" />
                  <Video className="w-8 h-8 text-gray-400" />
                </div>
                <p className="text-gray-400 text-center">
                  Click or drag media to upload
                  <span className="block text-sm mt-1">Supports images and videos (max 100MB)</span>
                  <span className="block text-sm mt-1 text-[#00B6DE]">میڈیا اپ لوڈ کرنے کے لیے کلک کریں یا ڈریگ کریں</span>
                </p>
              </>
            )}
          </div>
        </label>
      )}

      {error && (
        <div className="mt-4 p-3 bg-red-500/10 border border-red-500 rounded-lg">
          <p className="text-red-500 text-sm">{error}</p>
        </div>
      )}
    </div>
  );
}