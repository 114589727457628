import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Sparkles, ArrowRight, Shield, Award, UserCheck, MessageCircle, Zap, Droplets, Sun, Clock, Gauge, Syringe, FlaskRound as Flask, Search, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  {
    category: "Ultra Fast Laser Hair Removal",
    urduTitle: "الٹرا فاسٹ لیزر ہیئر ریموول",
    description: "State-of-the-art laser technology for permanent hair reduction. Our advanced system is safe for all skin types and provides faster, more comfortable treatments with better results.",
    urduDescription: "مستقل بالوں کی کمی کے لیے جدید لیزر ٹیکنالوجی۔ ہمارا ایڈوانسڈ سسٹم تمام جلد کی اقسام کے لیے محفوظ ہے۔",
    icon: Gauge,
    link: "/medspa-treatments",
    items: [
      "Permanent hair reduction",
      "Safe for all skin types",
      "Faster treatments",
      "Minimal discomfort",
      "Full body packages available",
      "Latest technology"
    ]
  },
  {
    category: "Hair Transplant",
    urduTitle: "بال ٹرانسپلانٹ",
    description: "Advanced FUE hair transplant for natural-looking results. Restore your confidence with our expert hair restoration solutions.",
    urduDescription: "قدرتی نظر آنے والے نتائج کے لیے ایڈوانسڈ ایف یو ای بال ٹرانسپلانٹ۔",
    icon: Award,
    link: "/hair-transplant",
    items: [
      "Natural-looking results",
      "Permanent solution",
      "Minimal scarring",
      "Local anesthesia",
      "Expert surgeons",
      "Latest techniques"
    ]
  },
  {
    category: "Hair Loss Treatment",
    urduTitle: "بالوں کے گرنے کا علاج",
    description: "Comprehensive solutions for all types of hair loss, including PRP therapy, medications, and advanced treatments.",
    urduDescription: "بالوں کے گرنے کی تمام اقسام کے لیے جامع حل، بشمول پی آر پی تھراپی، ادویات، اور جدید علاج۔",
    icon: Shield,
    link: "/hair-transplant",
    items: [
      "PRP therapy",
      "Minoxidil treatment",
      "Finasteride",
      "Exosome therapy",
      "Hair growth boosters",
      "Scalp treatments"
    ]
  },
  {
    category: "Medspa Treatments",
    urduTitle: "میڈسپا علاج",
    description: "Advanced facial treatments using cutting-edge technology and medical-grade products",
    urduDescription: "جدید ٹیکنالوجی اور طبی درجے کی مصنوعات کا استعمال کرتے ہوئے جدید چہرے کے علاج",
    icon: Droplets,
    link: "/medspa-treatments",
    items: [
      "Medical Facials | میڈیکل فیشلز",
      "Peel To Reveal | پیل ٹو ریویل",
      "PRP (Vampire Facial) | پی آر پی (ویمپائر فیشل)",
      "Prescription Skin Care | نسخہ جاتی جلد کی دیکھ بھال",
      "Microneedling | مائیکرونیڈلنگ",
      "Derma Roller | ڈرما رولر"
    ]
  },
  {
    category: "Injectable Treatments",
    urduTitle: "انجکشن والے علاج",
    description: "Premium dermal fillers and muscle relaxants for natural-looking results",
    urduDescription: "قدرتی نظر آنے والے نتائج کے لیے پریمیم ڈرمل فلرز اور مسل ریلیکسنٹس",
    icon: Syringe,
    link: "/injectable-treatments",
    items: [
      "Lip Enhancement | ہونٹوں کی خوبصورتی",
      "Non-Surgical Nose Job | بغیر سرجری ناک کی تشکیل",
      "Cheek Augmentation | گالوں کی تشکیل",
      "Jawline Definition | جبڑے کی تعریف",
      "Anti-Wrinkle | جھریوں کے خلاف",
      "Chin Enhancement | ٹھوڑی کی تشکیل"
    ]
  },
  {
    category: "Skin Treatments",
    urduTitle: "جلد کے علاج",
    description: "Comprehensive solutions for all skin concerns and conditions",
    urduDescription: "جلد کے تمام مسائل اور حالات کے لیے جامع حل",
    icon: Flask,
    link: "/skin-rejuvenation-dermatology-lahore",
    items: [
      "Acne Treatment | مہاسوں کا علاج",
      "Pigmentation | رنگت",
      "Scar Revision | زخم کی اصلاح",
      "Thread Veins | نسوں کا علاج",
      "Skin Tightening | جلد کی کشیدگی",
      "Anti-Aging | عمر کے اثرات کے خلاف"
    ]
  }
];

export function ServicesSection() {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <>
      {/* Decorative Background Break */}
      <div className="relative w-full h-20 overflow-hidden bg-gray-900">
        {/* Gradient Orbs */}
        <div className="absolute -top-10 -left-10 w-40 h-40 bg-emerald-500/30 rounded-full blur-3xl"></div>
        <div className="absolute -top-5 left-1/4 w-32 h-32 bg-blue-500/20 rounded-full blur-3xl"></div>
        <div className="absolute -top-8 right-1/3 w-36 h-36 bg-purple-500/20 rounded-full blur-3xl"></div>
        <div className="absolute -top-12 right-1/4 w-40 h-40 bg-pink-500/20 rounded-full blur-3xl"></div>
        <div className="absolute -top-6 right-0 w-36 h-36 bg-emerald-500/20 rounded-full blur-3xl"></div>
        
        {/* Overlay gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-900/50 to-gray-900"></div>
      </div>

      <section id="treatments-section" className="min-h-screen py-12 bg-gray-900">
        <div className="container mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl md:text-5xl font-bold gradient-text mb-4">
              Our Expert Services
              <span className="block text-2xl mt-2 urdu-text">ہماری ماہرانہ خدمات</span>
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Discover our range of advanced aesthetic treatments
              <span className="block mt-2 text-lg urdu-text">ہمارے جدید جمالیاتی علاج دریافت کریں</span>
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-6">
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-6 hover-scale"
              >
                <div className="flex items-center gap-2 mb-4">
                  <service.icon className="w-6 h-6 text-[#00B6DE]" />
                  <h3 className="text-2xl font-bold text-white">{service.category}</h3>
                </div>
                <p className="text-lg urdu-text mb-3">{service.urduTitle}</p>
                <p className="text-gray-300 mb-2">{service.description}</p>
                <p className="text-sm urdu-text mb-4">{service.urduDescription}</p>
                
                <ul className="space-y-2">
                  {service.items.map((item, i) => (
                    <li key={i} className="flex items-center text-gray-300">
                      <Sparkles className="w-4 h-4 text-emerald-400 mr-2 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>

                {service.link && (
                  <Link
                    to={service.link}
                    className="mt-6 btn-primary w-full inline-flex items-center justify-center text-sm py-2"
                  >
                    View Details
                  </Link>
                )}
              </motion.div>
            ))}
          </div>

          {/* Call to Action Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="mt-16 glass-card p-8 rounded-2xl"
          >
            <div className="text-center mb-8">
              <h3 className="text-3xl font-bold gradient-text mb-4">
                Ready to Start Your Beauty Journey?
                <span className="block text-xl mt-2 text-[#00B6DE]">اپنے خوبصورتی کے سفر کی شروعات کے لیے تیار ہیں؟</span>
              </h3>
              <p className="text-gray-300 max-w-2xl mx-auto">
                Book a consultation or check our treatment prices. Our experts are here to help you achieve your beauty goals.
                <span className="block mt-2 text-[#00B6DE]">مشاورت بک کریں یا ہمارے علاج کی قیمتیں چیک کریں۔ ہمارے ماہرین آپ کے خوبصورتی کے مقاصد کو حاصل کرنے میں آپ کی مدد کے لیے موجود ہیں۔</span>
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
              <Link 
                to="/price-lahore-skin-clinic"
                className="btn-primary inline-flex items-center justify-center"
              >
                <span>View Treatment Prices</span>
                <ArrowRight className="w-4 h-4 ml-2" />
              </Link>

              <a 
                href="https://wa.me/923194569401"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary inline-flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                WhatsApp Consultation
              </a>

              <a 
                href="tel:+924237896975"
                className="btn-secondary inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call: 042-37896975
              </a>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="mt-12 max-w-3xl mx-auto text-center"
          >
            <div className="glass-card p-6 rounded-2xl">
              <h3 className="text-2xl font-bold text-white mb-4">
                Talk to Us in Urdu
                <span className="block text-lg mt-2 urdu-text">ہم سے اردو میں بات کریں</span>
              </h3>
              
              <div className="relative mb-4">
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  className="w-full py-3 pl-12 pr-4 text-white bg-gray-800/50 border border-gray-700 rounded-full shadow-lg backdrop-blur-sm focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                  placeholder="Search treatments... علاج تلاش کریں۔۔۔"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <a 
                href="https://wa.me/923194569401"
                className="btn-primary inline-flex items-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                Chat with Us on WhatsApp
                <span className="block text-sm ml-2 opacity-90">واٹس ایپ پر ہم سے بات کریں</span>
              </a>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
}