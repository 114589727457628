import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Clock, Heart, AlertCircle, ArrowRight, MessageCircle, Phone, Star, Shield, Award, UserCheck, CheckCircle, Syringe, Timer, Activity, Sparkles, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const treatments = [
  {
    title: "PRP (Platelet-Rich Plasma)",
    description: "A safe, non-surgical treatment where your own blood is used to boost hair growth naturally. A small blood sample is taken, processed to collect growth-rich platelets, and injected into thinning scalp areas.",
    duration: "30–45 minutes per session",
    recovery: "Very minimal—resume daily activities right away",
    results: "Noticeable within 3–6 months",
    link: "/medspa-treatments#microneedling"
  },
  {
    title: "Mesotherapy",
    description: "A nutrient-rich treatment that injects vitamins, minerals, and nutrients directly into the scalp to nourish hair follicles. This strengthens hair from the roots, promoting natural hair growth.",
    duration: "About 30 minutes",
    recovery: "Mild scalp sensitivity",
    results: "Stronger, healthier hair after a few sessions",
    link: "/medspa-treatments#mesotherapy"
  },
  {
    title: "Microneedling",
    description: "Uses tiny needles to create small 'injuries' on the scalp. These signals boost collagen production, stimulating hair growth and strengthening follicles.",
    duration: "Around 30 minutes",
    recovery: "Mild redness for a day",
    results: "Noticeable growth within months",
    link: "/medspa-treatments#microneedling"
  },
  {
    title: "Exosome Therapy",
    description: "A cutting-edge, non-surgical treatment using tiny cell particles to support hair regrowth. These particles, called exosomes, are injected into the scalp, delivering growth signals to revive weak hair follicles.",
    duration: "Around 30–45 minutes per session",
    recovery: "Minimal downtime",
    results: "Improvements within months",
    link: "/medspa-treatments#exosome"
  }
];

const concerns = [
  {
    name: "Androgenic Alopecia",
    description: "Male/Female pattern baldness"
  },
  {
    name: "Telogen Effluvium",
    description: "Stress-related hair loss"
  },
  {
    name: "Seborrheic Dermatitis",
    description: "Dandruff and itchy scalp"
  },
  {
    name: "Alopecia Areata",
    description: "Sudden bald patches"
  },
  {
    name: "Traction Alopecia",
    description: "Hair loss from styling"
  },
  {
    name: "Premature Canities",
    description: "Early gray hair"
  },
  {
    name: "Scalp Psoriasis",
    description: "Red, flaky scalp patches"
  },
  {
    name: "Trichorrhexis Nodosa",
    description: "Weak and dry hair"
  },
  {
    name: "Folliculitis",
    description: "Inflamed hair follicles"
  },
  {
    name: "Diffuse Thinning",
    description: "Hair becoming thinner"
  }
];

const signs = [
  "Hair on pillow",
  "Clumps in shower",
  "Hair on brush",
  "Receding hairline",
  "Sudden bald spots",
  "Thinning hair strands",
  "Scalp showing through"
];

export function HairLoss() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="relative min-h-[60vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 gradient-text">
              What Is Hair Loss?
              <span className="block text-3xl mt-4 text-[#00B6DE]">بالوں کا گرنا کیا ہے؟</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Hair loss occurs when hair on your scalp becomes thinner, falls out, or stops growing. Losing 50-100 hairs daily is normal, but when more falls out than grows back, thinning or bald patches appear.
              <span className="block mt-4 text-lg text-[#00B6DE]">
                This affects men and women equally and may be worsened by stress, lifestyle or age which can upset this balance, making hair loss visible.
              </span>
            </p>

            <div className="grid md:grid-cols-3 gap-6 mt-12">
              <div className="glass-card p-6">
                <Shield className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Expert Care</h3>
                <p className="text-sm text-gray-400">Focused Just on You</p>
              </div>
              <div className="glass-card p-6">
                <Award className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Advanced Solutions</h3>
                <p className="text-sm text-gray-400">Treatments That Work</p>
              </div>
              <div className="glass-card p-6">
                <UserCheck className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Personalized Care</h3>
                <p className="text-sm text-gray-400">Custom Treatment Plans</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Signs Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold gradient-text mb-4">
              Signs of Too Much Hair Loss
              <span className="block text-2xl mt-2 text-[#00B6DE]">بالوں کے گرنے کی علامات</span>
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-6">
            {signs.map((sign, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-4 flex items-center gap-3"
              >
                <CheckCircle className="w-5 h-5 text-[#00B6DE] flex-shrink-0" />
                <p className="text-gray-300">{sign}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Treatments Section */}
      <section className="py-20 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold gradient-text mb-4">
              Best Hair Loss Treatments At Glow & GO
              <span className="block text-2xl mt-2 text-[#00B6DE]">گلو اینڈ گو میں بالوں کے گرنے کے بہترین علاج</span>
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {treatments.map((treatment, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-6"
              >
                <h3 className="text-2xl font-bold text-white mb-4">{treatment.title}</h3>
                <p className="text-gray-300 mb-6">{treatment.description}</p>
                
                <div className="space-y-4 mb-6">
                  <div className="flex items-center gap-2">
                    <Timer className="w-4 h-4 text-[#00B6DE]" />
                    <p className="text-sm text-gray-300">Duration: {treatment.duration}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Activity className="w-4 h-4 text-[#00B6DE]" />
                    <p className="text-sm text-gray-300">Recovery: {treatment.recovery}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <CheckCircle className="w-4 h-4 text-[#00B6DE]" />
                    <p className="text-sm text-gray-300">Results: {treatment.results}</p>
                  </div>
                </div>

                <Link
                  to={treatment.link}
                  className="btn-primary inline-flex items-center text-sm"
                >
                  Learn More
                  <ArrowRight className="w-4 h-4 ml-2" />
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Common Concerns */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold gradient-text mb-4">
              Pakistan's Top Hair Concerns
              <span className="block text-2xl mt-2 text-[#00B6DE]">پاکستان کے اہم بالوں کے مسائل</span>
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {concerns.map((concern, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-6"
              >
                <div className="flex items-start gap-4">
                  <Info className="w-5 h-5 text-[#00B6DE] flex-shrink-0" />
                  <div>
                    <h3 className="text-lg font-semibold text-white">{concern.name}</h3>
                    <p className="text-gray-300 text-sm">{concern.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass-card p-8 rounded-2xl text-center"
          >
            <h2 className="text-3xl font-bold gradient-text mb-4">
              Book Your Consultation Today
              <span className="block text-xl mt-2 text-[#00B6DE]">آج ہی اپنی مشاورت بک کریں</span>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-8">
              Take the first step towards healthier hair. Our experts will create a personalized treatment plan for you.
              <span className="block mt-2 text-[#00B6DE]">صحت مند بالوں کی طرف پہلا قدم اٹھائیں۔ ہمارے ماہرین آپ کے لیے ذاتی علاج کا منصوبہ بنائیں گے۔</span>
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a 
                href="https://wa.me/923194569401"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary inline-flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                WhatsApp Consultation
              </a>
              <a 
                href="tel:+924237896975"
                className="btn-secondary inline-flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call: 042-37896975
              </a>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}