import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Helper function to check if user is authenticated
export async function isAuthenticated() {
  const { data: { session }, error } = await supabase.auth.getSession();
  return !!session && !error;
}

// Helper function to get storage URL
export function getStorageUrl(bucket: string, path: string) {
  return `${supabaseUrl}/storage/v1/object/public/${bucket}/${path}`;
}

// Helper function to create bucket if it doesn't exist
export async function ensureBucketExists(bucketName: string) {
  try {
    const { data: buckets } = await supabase.storage.listBuckets();
    const bucketExists = buckets?.some(bucket => bucket.name === bucketName);

    if (!bucketExists) {
      const { error } = await supabase.storage.createBucket(bucketName, {
        public: true,
        allowedMimeTypes: ['image/*', 'video/*', 'application/pdf', 'text/*'],
        fileSizeLimit: 104857600 // 100MB
      });

      if (error) throw error;
    }
  } catch (error) {
    console.error('Error ensuring bucket exists:', error);
    throw error;
  }
}

// Helper function to validate file size
export function validateFileSize(file: File, maxSize: number = 100 * 1024 * 1024) { // 100MB default
  if (file.size > maxSize) {
    throw new Error(`File size exceeds ${Math.round(maxSize / (1024 * 1024))}MB limit`);
  }
  return true;
}