import React, { useRef } from 'react';
import { Search, ArrowRight } from 'lucide-react';
import { VoiceSearch } from './VoiceSearch';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  onSend: () => void;
  placeholder?: string;
}

export function SearchBar({ value, onChange, onSend, placeholder }: SearchBarProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle Enter key
    if (e.key === 'Enter' && value.trim()) {
      e.preventDefault();
      onSend();
    }
    // Handle right arrow key when cursor is at the end
    else if (e.key === 'ArrowRight' && inputRef.current) {
      const input = inputRef.current;
      if (input.selectionStart === input.value.length && value.trim()) {
        e.preventDefault();
        onSend();
      }
    }
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      if (value.trim()) onSend();
    }} className="relative">
      <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <input
        ref={inputRef}
        type="text"
        className="w-full py-4 pl-12 pr-24 text-white bg-gray-800/50 border border-gray-700 rounded-full shadow-lg backdrop-blur-sm focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
        placeholder={placeholder || "Search treatments... علاج تلاش کریں۔۔۔"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center gap-2">
        <VoiceSearch onSearch={onChange} />
        <button
          type="submit"
          className="p-2 bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!value.trim()}
        >
          <ArrowRight className="w-5 h-5" />
        </button>
      </div>
    </form>
  );
}