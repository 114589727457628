import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, X, Send, Loader2, Mic, MicOff, Languages } from 'lucide-react';
import OpenAI from 'openai';

interface Message {
  role: 'user' | 'assistant';
  content: string;
  language?: 'en' | 'ur';
  timestamp: number;
}

interface Memory {
  messages: Message[];
  summary?: string;
}

const SYSTEM_PROMPT = `You are a friendly skin care expert at Glow & Go clinic in Lahore. Speak naturally and conversationally, like you're chatting with a friend. Always show empathy and understanding.

Key Information:
- Location: 27-K, Sir Syed Road, Block-K, Gulberg-II, Lahore
- Contact: 042-37896975, 042-37896977
- Hours: Monday - Saturday: 10:00 AM - 8:00 PM, Sunday: Closed

Treatment Guidelines:
1. Always suggest booking a consultation for personalized advice
2. Mention specific treatments we offer for each concern
3. Be empathetic and understanding
4. Use casual, friendly language in both English and Urdu
5. Encourage sending pictures on WhatsApp for better assessment

Available Treatments:

For Acne:
- Medical-grade facials with AHA/BHA
- Specialized acne treatments
- Chemical peels
- LED light therapy
- Prescription skincare
- PRP therapy

For Anti-Aging:
- HIFU treatment
- Dermal fillers
- Botox
- PDO threads
- Vampire facial
- Mesotherapy

For Pigmentation:
- Chemical peels
- Laser treatments
- Vitamin C treatments
- Brightening facials
- PRP with microneedling

For Hair Removal:
- Advanced laser hair removal
- Available for all skin types
- Full body treatments
- Face and sensitive areas

For Skin Tightening:
- HIFU
- RF microneedling
- PDO threads
- Dermal fillers
- Non-surgical facelifts

Previous Context: {context}`;

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

function detectLanguage(text: string): 'en' | 'ur' {
  const urduPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return urduPattern.test(text) ? 'ur' : 'en';
}

async function summarizeConversation(messages: Message[]): Promise<string> {
  try {
    const recentMessages = messages.slice(-5);
    const conversationText = recentMessages
      .map(msg => `${msg.role}: ${msg.content}`)
      .join('\n');

    const completion = await openai.chat.completions.create({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content: 'Summarize the key points of this conversation in 2-3 sentences.'
        },
        {
          role: 'user',
          content: conversationText
        }
      ],
      temperature: 0.7,
      max_tokens: 150
    });

    return completion.choices[0].message.content || '';
  } catch (error) {
    console.error('Error summarizing conversation:', error);
    return '';
  }
}

export type ChatBotHandle = {
  handleSend: (message: string) => Promise<void>;
  openChat: () => void;
};

export const ChatBot = forwardRef<ChatBotHandle>((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [voiceError, setVoiceError] = useState<string | null>(null);
  const [detectedLanguage, setDetectedLanguage] = useState<'en' | 'ur'>('en');
  const [memory, setMemory] = useState<Memory>({ messages: [] });
  const [timeLeft, setTimeLeft] = useState(15);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const transcriptRef = useRef<string>('');

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (input) {
      setDetectedLanguage(detectLanguage(input));
    }
  }, [input]);

  useEffect(() => {
    const savedMemory = localStorage.getItem('chatMemory');
    if (savedMemory) {
      const parsedMemory = JSON.parse(savedMemory);
      setMemory(parsedMemory);
      setMessages(parsedMemory.messages);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('chatMemory', JSON.stringify(memory));
  }, [memory]);

  useEffect(() => {
    if (isListening) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            stopListening();
            return 15;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setTimeLeft(15);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isListening]);

  const updateMemory = async (newMessages: Message[]) => {
    if (newMessages.length > 0) {
      const summary = await summarizeConversation(newMessages);
      setMemory({ messages: newMessages, summary });
    }
  };

  const handleSend = async (message = input) => {
    if (!message.trim()) return;

    const language = detectLanguage(message);
    const newMessage: Message = {
      role: 'user',
      content: message,
      language,
      timestamp: Date.now()
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setInput('');
    setIsLoading(true);
    setIsOpen(true);

    try {
      const systemPrompt = SYSTEM_PROMPT.replace(
        '{context}',
        memory.summary || 'No previous context available.'
      );

      const completion = await openai.chat.completions.create({
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: systemPrompt },
          ...newMessages.map(msg => ({ role: msg.role, content: msg.content }))
        ],
        temperature: 0.7,
        max_tokens: 1000
      });

      const response = completion.choices[0].message.content;
      const updatedMessages = [
        ...newMessages,
        { role: 'assistant', content: response, timestamp: Date.now() }
      ];
      
      setMessages(updatedMessages);
      await updateMemory(updatedMessages);
    } catch (error) {
      console.error('Chat error:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'I apologize, but I encountered an error. Please try again.\n\nمجھے معذرت، لیکن مجھے ایک خرابی کا سامنا کرنا پڑا۔ براہ کرم دوبارہ کوشش کریں۔',
        timestamp: Date.now()
      };
      setMessages([...newMessages, errorMessage]);
      await updateMemory([...newMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleSend,
    openChat: () => setIsOpen(true)
  }));

  const startListening = () => {
    try {
      setVoiceError(null);
      transcriptRef.current = '';
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = detectedLanguage === 'ur' ? 'ur-PK' : 'en-US';

      recognition.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';
        
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript + ' ';
            transcriptRef.current += transcript + ' ';
          } else {
            interimTranscript += transcript;
          }
        }

        // Update input with both final and interim results
        setInput(transcriptRef.current + interimTranscript);
      };

      recognition.onerror = (event) => {
        setVoiceError('Error recognizing speech');
        console.error('Speech recognition error:', event.error);
        stopListening();
      };

      recognition.onend = () => {
        if (transcriptRef.current) {
          setInput(transcriptRef.current.trim());
        }
        stopListening();
      };

      recognition.start();
      setIsListening(true);
      recognitionRef.current = recognition;
    } catch (error) {
      setVoiceError('Speech recognition not supported');
      console.error('Speech recognition error:', error);
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setIsListening(false);
    setTimeLeft(15);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
    else if (e.key === 'ArrowRight') {
      const textarea = e.currentTarget;
      if (textarea.selectionStart === textarea.value.length) {
        e.preventDefault();
        handleSend();
      }
    }
  };

  return (
    <div className="fixed inset-y-0 right-0 z-50 flex items-end sm:items-center justify-end">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '100%', opacity: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="bg-gray-900 shadow-xl w-full sm:w-[600px] h-full sm:h-[600px] sm:mr-4 sm:mb-4 sm:rounded-2xl flex flex-col"
          >
            <div className="p-4 border-b border-gray-800 flex items-center justify-between">
              <h2 className="text-xl font-bold text-white flex items-center gap-2">
                Chat with Us
                <span className="text-[#00B6DE]">ہم سے بات کریں</span>
              </h2>
              <button
                onClick={() => setIsOpen(false)}
                className="p-2 hover:bg-gray-800 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-400" />
              </button>
            </div>

            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`max-w-[85%] rounded-2xl p-4 ${
                      message.role === 'user'
                        ? 'bg-[#00B6DE] text-white'
                        : 'bg-gray-800 text-gray-200'
                    }`}
                  >
                    {message.language && (
                      <div className="flex items-center gap-1 mb-1 text-xs opacity-70">
                        <Languages className="w-3 h-3" />
                        {message.language === 'ur' ? 'Urdu' : 'English'}
                      </div>
                    )}
                    <p className="whitespace-pre-wrap">{message.content}</p>
                    <div className="mt-2 text-xs opacity-50">
                      {new Date(message.timestamp).toLocaleTimeString()}
                    </div>
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="flex justify-start">
                  <div className="bg-gray-800 rounded-2xl p-4">
                    <Loader2 className="w-5 h-5 text-[#00B6DE] animate-spin" />
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            <div className="p-4 border-t border-gray-800">
              <div className="relative">
                <div className="absolute left-2 top-2 flex items-center gap-1 text-xs text-gray-400">
                  <Languages className="w-4 h-4" />
                  {detectedLanguage === 'ur' ? 'Urdu' : 'English'}
                </div>
                <textarea
                  ref={textareaRef}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type your message... اپنا پیغام ٹائپ کریں۔۔۔"
                  className="w-full py-3 px-4 pl-20 pr-24 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#00B6DE] resize-none"
                  rows={2}
                />
                <div className="absolute right-2 bottom-2 flex items-center gap-2">
                  <button
                    onClick={isListening ? stopListening : startListening}
                    className={`p-2 rounded-full transition-colors relative ${
                      isListening 
                        ? 'bg-red-500/20 text-red-500' 
                        : 'text-[#00B6DE] hover:bg-[#00B6DE]/20'
                    }`}
                    title={isListening ? 'Stop recording' : 'Start voice input'}
                  >
                    {isListening ? <MicOff className="w-5 h-5" /> : <Mic className="w-5 h-5" />}
                    {isListening && (
                      <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded whitespace-nowrap">
                        {timeLeft}s remaining
                      </span>
                    )}
                  </button>
                  <button
                    onClick={() => handleSend()}
                    disabled={isLoading || !input.trim()}
                    className="p-2 text-[#00B6DE] hover:bg-[#00B6DE]/20 rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Send className="w-5 h-5" />
                  </button>
                </div>
              </div>
              {voiceError && (
                <p className="mt-2 text-sm text-red-500">{voiceError}</p>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 flex flex-col items-center"
      >
        <div className="bg-[#00B6DE] hover:bg-[#0095B7] text-white p-3 rounded-full shadow-lg mb-1">
          <MessageCircle className="w-5 h-5 md:w-6 md:h-6" />
        </div>
        <span className="text-xs md:text-sm text-[#00B6DE]">ہم سے بات کریں</span>
      </motion.button>
    </div>
  );
});

ChatBot.displayName = 'ChatBot';

declare global {
  interface Window {
    SpeechRecognition: typeof SpeechRecognition;
    webkitSpeechRecognition: typeof SpeechRecognition;
  }
}