import React, { useState, useEffect } from 'react';
import { Upload, Trash2, Video, CheckCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { motion } from 'framer-motion';

interface MediaFile {
  id: string;
  name: string;
  url: string;
  created_at: string;
}

export function MediaSection() {
  const [files, setFiles] = useState<MediaFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  useEffect(() => {
    fetchMedia();
  }, []);

  async function fetchMedia() {
    try {
      const { data: mediaFiles, error } = await supabase
        .from('videos')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const filesWithUrls = await Promise.all(
        mediaFiles.map(async (file) => {
          const { data: { publicUrl } } = supabase
            .storage
            .from('videos')
            .getPublicUrl(file.name);

          return {
            id: file.id,
            name: file.name,
            url: publicUrl,
            created_at: file.created_at
          };
        })
      );

      setFiles(filesWithUrls);
    } catch (error) {
      console.error('Error fetching media:', error);
    }
  }

  async function uploadFile(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      setUploading(true);

      const file = event.target.files?.[0];
      if (!file) return;

      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;

      // Upload to storage
      const { data: storageData, error: storageError } = await supabase.storage
        .from('videos')
        .upload(fileName, file);

      if (storageError) throw storageError;

      // Create metadata record
      const { error: dbError } = await supabase
        .from('videos')
        .insert({
          name: fileName,
          storage_object_id: storageData.id
        });

      if (dbError) throw dbError;

      await fetchMedia();
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false);
    }
  }

  async function deleteFile(fileName: string) {
    try {
      // Delete from storage
      const { error: storageError } = await supabase.storage
        .from('videos')
        .remove([fileName]);

      if (storageError) throw storageError;

      // Delete metadata
      const { error: dbError } = await supabase
        .from('videos')
        .delete()
        .match({ name: fileName });

      if (dbError) throw dbError;

      await fetchMedia();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }

  return (
    <section className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold gradient-text mb-6">
            Media Library
            <span className="block text-2xl mt-2 urdu-text">میڈیا لائبریری</span>
          </h2>
          <p className="text-xl text-gray-300">
            Upload and manage your videos
            <span className="block mt-2 text-lg urdu-text">اپنی ویڈیوز اپ لوڈ اور منظم کریں</span>
          </p>
        </motion.div>

        <div className="mb-8">
          <label className="btn-primary flex items-center justify-center cursor-pointer">
            <input
              type="file"
              accept="video/*"
              onChange={uploadFile}
              disabled={uploading}
              className="hidden"
            />
            <Upload className="w-5 h-5 mr-2" />
            {uploading ? 'Uploading...' : 'Upload Video'}
          </label>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          {files.map((file) => (
            <motion.div
              key={file.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              className={`glass-card p-4 ${selectedFile === file.id ? 'ring-2 ring-emerald-500' : ''}`}
            >
              <div className="aspect-video mb-4 relative rounded-lg overflow-hidden bg-gray-800">
                <video
                  src={file.url}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                  <Video className="w-8 h-8 text-white" />
                </div>
              </div>
              
              <div className="flex items-center justify-between">
                <div className="truncate flex-1 mr-4">
                  <p className="text-sm text-gray-300 truncate">{file.name}</p>
                  <p className="text-xs text-gray-500">
                    {new Date(file.created_at).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setSelectedFile(file.id)}
                    className="p-2 text-emerald-400 hover:bg-emerald-500/20 rounded-full"
                  >
                    <CheckCircle className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => deleteFile(file.name)}
                    className="p-2 text-red-400 hover:bg-red-500/20 rounded-full"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}