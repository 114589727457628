import React from 'react';

export function Logo() {
  return (
    <div className="flex flex-col items-end">
      <div className="text-2xl font-bold">
        <span className="text-white">GLOW</span>
        <span className="text-white">&</span>
        <span className="text-[#00B6DE]">GO</span>
        <span className="text-white">™</span>
      </div>
      <span className="text-sm font-normal text-[#00B6DE] mt-1">خود پر اعتماد</span>
    </div>
  );
}