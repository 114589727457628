import React from 'react';
import { ArrowRight, Phone, Shield, Award, UserCheck, MessageCircle, Camera, MapPin } from 'lucide-react';
import { motion } from 'framer-motion';
import { supabase } from '../lib/supabase';
import { SearchBar } from './SearchBar';
import { Link } from 'react-router-dom';

interface HeroSectionProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  onSearch: () => void;
  onChatOpen: () => void;
}

export function HeroSection({ searchQuery, setSearchQuery, onSearch, onChatOpen }: HeroSectionProps) {
  const { data: { publicUrl } } = supabase.storage
    .from('videos')
    .getPublicUrl('0.032339581242209814.MP4');

  const scrollToTreatments = () => {
    const treatmentsSection = document.getElementById('treatments-section');
    if (treatmentsSection) {
      treatmentsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative min-h-screen flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
      {/* Video Background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-black/60 z-10"></div>
        <video
          src={publicUrl}
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>

      <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="container mx-auto relative z-30"
      >
        <div className="max-w-4xl mx-auto text-center mb-16">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 gradient-text"
          >
            Modern Aesthetic Clinic in Lahore
            <span className="block text-sm sm:text-base mt-2 text-gray-300">(by CosmeDocs UK)</span>
            <span className="block text-xl sm:text-2xl md:text-3xl mt-4 text-[#00B6DE]">لاہور میں جدید جمالیاتی کلینک</span>
          </motion.h1>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mb-8"
          >
            <button 
              onClick={scrollToTreatments}
              className="inline-flex items-center gap-2 px-8 py-4 bg-[#00B6DE] hover:bg-[#0095B7] rounded-full text-white transition-all shadow-lg hover:shadow-[#00B6DE]/25"
            >
              <span>View Our Treatments</span>
              <ArrowRight className="w-5 h-5" />
            </button>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="max-w-2xl mx-auto mb-12"
          >
            <SearchBar
              value={searchQuery}
              onChange={setSearchQuery}
              onSend={onSearch}
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto"
          >
            <button 
              onClick={onChatOpen}
              className="glass-card p-6 hover:scale-105 transition-transform"
            >
              <MessageCircle className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Chat to us</h3>
              <p className="text-gray-400">Get instant answers to your questions</p>
            </button>

            <a 
              href="https://wa.me/923194569401"
              target="_blank"
              rel="noopener noreferrer" 
              className="glass-card p-6 hover:scale-105 transition-transform"
            >
              <Camera className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Send Us Your Picture</h3>
              <p className="text-gray-400">WhatsApp Consultation</p>
            </a>

            <a 
              href="#location"
              className="glass-card p-6 hover:scale-105 transition-transform"
            >
              <MapPin className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Visit Us In Gulberg</h3>
              <p className="text-gray-400">Meet our experts in person</p>
            </a>
          </motion.div>
        </div>
      </motion.div>

      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none z-20">
        <div className="absolute -top-1/2 -right-1/2 w-full h-full bg-[#00B6DE]/20 rounded-full blur-3xl"></div>
        <div className="absolute -bottom-1/2 -left-1/2 w-full h-full bg-blue-500/20 rounded-full blur-3xl"></div>
      </div>
    </section>
  );
}