import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, Tag, MessageCircle, Phone, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function ChemicalPeels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black pt-20">
      {/* Hero Section */}
      <div className="relative min-h-[40vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center gap-4 mb-6">
              <span className="px-3 py-1 bg-[#00B6DE]/20 text-[#00B6DE] rounded-full text-sm">
                Skin Care
              </span>
              <div className="flex items-center text-gray-400 text-sm">
                <Calendar className="w-4 h-4 mr-1" />
                March 10, 2024
              </div>
              <div className="flex items-center text-gray-400 text-sm">
                <Clock className="w-4 h-4 mr-1" />
                4 min read
              </div>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold gradient-text mb-6">
              The Science Behind Chemical Peels
              <span className="block text-2xl mt-4 text-[#00B6DE]">کیمیکل پیلز کے پیچھے سائنس</span>
            </h1>
          </div>
        </motion.div>
      </div>

      {/* Article Content */}
      <article className="py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div className="prose prose-invert prose-lg max-w-none">
              <p>
                Chemical peels are one of the most effective treatments for achieving fresher, younger-looking skin. But what exactly happens during a chemical peel, and why are they so effective at treating various skin concerns?
                <span className="block mt-2 text-[#00B6DE]">کیمیکل پیلز تازہ، جوان نظر آنے والی جلد حاصل کرنے کے لیے سب سے زیادہ مؤثر علاج میں سے ایک ہیں۔</span>
              </p>

              <h2>What is a Chemical Peel?</h2>
              <p>
                A chemical peel is a skin-resurfacing procedure that uses a chemical solution to remove the top layers of skin, revealing the smoother, younger skin underneath. The solution creates a controlled injury to the skin, which prompts it to regenerate and produce more collagen.
                <span className="block mt-2 text-[#00B6DE]">کیمیکل پیل ایک جلد کی سطح کو نئی شکل دینے والا طریقہ کار ہے جو جلد کی اوپری تہوں کو ہٹانے کے لیے کیمیائی محلول کا استعمال کرتا ہے۔</span>
              </p>

              <h2>Types of Chemical Peels</h2>
              <h3>1. Superficial Peels (Peel To Reveal Plus)</h3>
              <p>
                - Uses mild acids like alpha-hydroxy acid
                - Gently exfoliates the outer layer of skin
                - Minimal downtime
                - Ideal for mild skin concerns
                - Price: Rs. 8,000
              </p>

              <h3>2. Medium-Depth Peels (Peel To Reveal Intense)</h3>
              <p>
                - Uses trichloroacetic acid (TCA)
                - Penetrates to the middle layers of skin
                - More dramatic results
                - Treats deeper wrinkles and pigmentation
                - Price: Rs. 10,000
              </p>

              <h2>Benefits of Chemical Peels</h2>
              <ul>
                <li>Reduces fine lines and wrinkles</li>
                <li>Improves skin texture and tone</li>
                <li>Treats acne and acne scars</li>
                <li>Reduces hyperpigmentation</li>
                <li>Stimulates collagen production</li>
                <li>Enhances skin radiance</li>
              </ul>

              <h2>The Recovery Process</h2>
              <p>
                After a chemical peel, your skin will go through several stages of healing:
                <span className="block mt-2 text-[#00B6DE]">کیمیکل پیل کے بعد، آپ کی جلد شفایابی کے کئی مراحل سے گزرے گی:</span>
              </p>
              <ol>
                <li>Initial redness and sensitivity (1-2 days)</li>
                <li>Peeling and flaking (3-7 days)</li>
                <li>New skin emergence (7-14 days)</li>
                <li>Final results (2-4 weeks)</li>
              </ol>

              <h2>Why Choose Glow & Go for Chemical Peels?</h2>
              <p>
                At Glow & Go, our chemical peels are performed by experienced dermatologists using medical-grade products. We customize each treatment to your specific skin concerns and type, ensuring optimal results with minimal downtime.
                <span className="block mt-2 text-[#00B6DE]">گلو اینڈ گو میں، ہمارے کیمیکل پیلز تجربہ کار ڈرماٹولوجسٹس کی طرف سے طبی درجے کی مصنوعات کا استعمال کرتے ہوئے کیے جاتے ہیں۔</span>
              </p>
            </div>

            {/* Call to Action */}
            <div className="mt-12 glass-card p-8 rounded-2xl text-center">
              <h2 className="text-3xl font-bold gradient-text mb-4">
                Ready to Transform Your Skin?
                <span className="block text-xl mt-2 text-[#00B6DE]">اپنی جلد کو تبدیل کرنے کے لیے تیار ہیں؟</span>
              </h2>
              <p className="text-gray-300 mb-8">
                Book your chemical peel consultation today and take the first step towards radiant, younger-looking skin.
                <span className="block mt-2 text-[#00B6DE]">آج ہی اپنی کیمیکل پیل مشاورت بک کریں اور چمکدار، جوان نظر آنے والی جلد کی طرف پہلا قدم اٹھائیں۔</span>
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <a 
                  href="https://wa.me/923194569401"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary inline-flex items-center justify-center"
                >
                  <MessageCircle className="w-5 h-5 mr-2" />
                  WhatsApp Consultation
                </a>
                <a 
                  href="tel:+924237896975"
                  className="btn-secondary inline-flex items-center justify-center"
                >
                  <Phone className="w-5 h-5 mr-2" />
                  Call: 042-37896975
                </a>
              </div>
            </div>

            {/* Tags */}
            <div className="mt-12 flex flex-wrap gap-4">
              <Link to="/blog/tag/chemical-peels" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Chemical Peels
              </Link>
              <Link to="/blog/tag/skin-care" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Skin Care
              </Link>
              <Link to="/blog/tag/anti-aging" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Anti-Aging
              </Link>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}