import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Star, Quote } from 'lucide-react';

const reviews = [
  {
    name: "Sana Khan",
    rating: 5,
    text: "Best skin clinic in Lahore! Dr. Sadia and her team are extremely professional and knowledgeable. The results of my treatment were amazing.",
    urduText: "لاہور کا بہترین سکن کلینک! ڈاکٹر سعدیہ اور ان کی ٹیم انتہائی پیشہ ور اور علم رکھتی ہے۔ میرے علاج کے نتائج حیرت انگیز تھے۔",
    date: "2024-02-15"
  },
  {
    name: "Amna Malik",
    rating: 5,
    text: "Very satisfied with my treatment at Glow & Go. The staff is friendly and the clinic is very clean and modern. Highly recommended!",
    urduText: "گلو اینڈ گو میں اپنے علاج سے بہت مطمئن ہوں۔ سٹاف دوستانہ ہے اور کلینک بہت صاف اور جدید ہے۔ بہت سفارش کی جاتی ہے!",
    date: "2024-02-10"
  },
  {
    name: "Fatima Ahmed",
    rating: 5,
    text: "Finally found a clinic that delivers what they promise. The treatments are effective and reasonably priced. Thank you Glow & Go!",
    urduText: "آخرکار ایک ایسا کلینک مل گیا جو اپنے وعدوں پر پورا اترتا ہے۔ علاج مؤثر اور مناسب قیمت پر ہیں۔ گلو اینڈ گو کا شکریہ!",
    date: "2024-02-05"
  },
  {
    name: "Zara Shah",
    rating: 5,
    text: "Professional service with amazing results. The doctors really know what they're doing. Best decision I made for my skin!",
    urduText: "پیشہ ورانہ خدمت حیرت انگیز نتائج کے ساتھ۔ ڈاکٹرز واقعی جانتے ہیں کہ وہ کیا کر رہے ہیں۔ میری جلد کے لیے بہترین فیصلہ!",
    date: "2024-01-30"
  }
];

export function ReviewSlider() {
  return (
    <div className="relative">
      <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="bg-[#00B6DE] w-12 h-12 rounded-full flex items-center justify-center shadow-lg">
          <Quote className="w-6 h-6 text-white" />
        </div>
      </div>
      
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={30}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        className="py-8"
      >
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50 h-full flex flex-col">
              <div className="flex items-center mb-4">
                {[...Array(review.rating)].map((_, i) => (
                  <Star key={i} className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                ))}
              </div>
              <div className="flex-grow">
                <p className="text-gray-300 mb-2">{review.text}</p>
                <p className="text-sm text-[#00B6DE] mb-4 leading-relaxed">{review.urduText}</p>
              </div>
              <div className="mt-auto pt-4 border-t border-gray-700/50">
                <p className="font-semibold text-white">{review.name}</p>
                <p className="text-sm text-gray-400">{new Date(review.date).toLocaleDateString()}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}