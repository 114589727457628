import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, Tag, MessageCircle, Phone, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function DermalFillers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black pt-20">
      {/* Hero Section */}
      <div className="relative min-h-[40vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center gap-4 mb-6">
              <span className="px-3 py-1 bg-[#00B6DE]/20 text-[#00B6DE] rounded-full text-sm">
                Aesthetics
              </span>
              <div className="flex items-center text-gray-400 text-sm">
                <Calendar className="w-4 h-4 mr-1" />
                March 5, 2024
              </div>
              <div className="flex items-center text-gray-400 text-sm">
                <Clock className="w-4 h-4 mr-1" />
                6 min read
              </div>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold gradient-text mb-6">
              Everything You Need to Know About Dermal Fillers
              <span className="block text-2xl mt-4 text-[#00B6DE]">ڈرمل فلرز کے بارے میں آپ کو جو جاننے کی ضرورت ہے</span>
            </h1>
          </div>
        </motion.div>
      </div>

      {/* Article Content */}
      <article className="py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div className="prose prose-invert prose-lg max-w-none">
              <p>
                Dermal fillers have revolutionized the field of aesthetic medicine, offering a non-surgical solution to restore volume, smooth wrinkles, and enhance facial features. Let's explore everything you need to know about this popular treatment.
                <span className="block mt-2 text-[#00B6DE]">ڈرمل فلرز نے حسن و جمال کی طب کے شعبے میں انقلاب برپا کیا ہے، جو حجم کی بحالی، جھریوں کو ہموار کرنے اور چہرے کی خصوصیات کو بڑھانے کے لیے غیر سرجیکل حل پیش کرتا ہے۔</span>
              </p>

              <h2>What Are Dermal Fillers?</h2>
              <p>
                Dermal fillers are gel-like substances, primarily made of hyaluronic acid, that are injected beneath the skin to restore lost volume, smooth lines, soften creases, or enhance facial contours.
                <span className="block mt-2 text-[#00B6DE]">ڈرمل فلرز جیل جیسے مادے ہیں، بنیادی طور پر ہائلورونک ایسڈ سے بنے ہوتے ہیں۔</span>
              </p>

              <h2>Types of Fillers We Offer</h2>
              <h3>1. Hyaluronic Acid Fillers</h3>
              <ul>
                <li>Facial Lines & Wrinkles: Rs. 35,000/ML</li>
                <li>Lips Enhancement: Rs. 38,500/ML</li>
                <li>Cheek Augmentation: Rs. 38,500/ML</li>
                <li>Under Eye Circles: Rs. 45,000/ML</li>
              </ul>

              <h3>2. Premium Fillers</h3>
              <ul>
                <li>Radiesse Filler: Rs. 90,000/ML</li>
                <li>Ellanse Filler: Rs. 60,500/ML - Rs. 121,000/2ML</li>
                <li>Profhilo: Rs. 105,000</li>
              </ul>

              <h2>Popular Treatment Areas</h2>
              <ol>
                <li>
                  <strong>Lips:</strong> Enhancement and definition
                </li>
                <li>
                  <strong>Cheeks:</strong> Volume restoration and contouring
                </li>
                <li>
                  <strong>Nasolabial Folds:</strong> Smoothing smile lines
                </li>
                <li>
                  <strong>Under Eyes:</strong> Dark circle and hollow treatment
                </li>
                <li>
                  <strong>Jawline:</strong> Definition and contouring
                </li>
              </ol>

              <h2>The Treatment Process</h2>
              <p>
                Your filler journey at Glow & Go includes:
                <span className="block mt-2 text-[#00B6DE]">گلو اینڈ گو میں آپ کا فلر سفر شامل ہے:</span>
              </p>
              <ol>
                <li>Consultation and assessment</li>
                <li>Treatment planning</li>
                <li>Numbing application</li>
                <li>Expert injection</li>
                <li>Post-treatment care</li>
              </ol>

              <h2>Results and Recovery</h2>
              <p>
                Results are visible immediately, with full results developing over 2 weeks. Most patients return to normal activities immediately, with minimal downtime.
                <span className="block mt-2 text-[#00B6DE]">نتائج فوری طور پر نظر آتے ہیں، 2 ہفتوں میں مکمل نتائج سامنے آتے ہیں۔</span>
              </p>

              <h2>Why Choose Glow & Go for Fillers?</h2>
              <ul>
                <li>Expert injectors with extensive training</li>
                <li>Premium, FDA-approved products</li>
                <li>Natural-looking results</li>
                <li>Customized treatment plans</li>
                <li>State-of-the-art facilities</li>
              </ul>
            </div>

            {/* Call to Action */}
            <div className="mt-12 glass-card p-8 rounded-2xl text-center">
              <h2 className="text-3xl font-bold gradient-text mb-4">
                Ready to Enhance Your Natural Beauty?
                <span className="block text-xl mt-2 text-[#00B6DE]">اپنی قدرتی خوبصورتی کو بڑھانے کے لیے تیار ہیں؟</span>
              </h2>
              <p className="text-gray-300 mb-8">
                Book your consultation today and discover how dermal fillers can help you achieve your aesthetic goals.
                <span className="block mt-2 text-[#00B6DE]">آج ہی اپنی مشاورت بک کریں اور جانیں کہ ڈرمل فلرز آپ کے حسن کے مقاصد کو حاصل کرنے میں کیسے مدد کر سکتے ہیں۔</span>
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <a 
                  href="https://wa.me/923194569401"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary inline-flex items-center justify-center"
                >
                  <MessageCircle className="w-5 h-5 mr-2" />
                  WhatsApp Consultation
                </a>
                <a 
                  href="tel:+924237896975"
                  className="btn-secondary inline-flex items-center justify-center"
                >
                  <Phone className="w-5 h-5 mr-2" />
                  Call: 042-37896975
                </a>
              </div>
            </div>

            {/* Tags */}
            <div className="mt-12 flex flex-wrap gap-4">
              <Link to="/blog/tag/dermal-fillers" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Dermal Fillers
              </Link>
              <Link to="/blog/tag/aesthetics" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Aesthetics
              </Link>
              <Link to="/blog/tag/anti-aging" className="flex items-center gap-2 px-4 py-2 bg-gray-800/50 hover:bg-[#00B6DE]/20 border border-gray-700 rounded-full text-gray-300 hover:text-[#00B6DE] transition-colors">
                <Tag className="w-4 h-4" />
                Anti-Aging
              </Link>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}