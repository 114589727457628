import React from 'react';
import { motion } from 'framer-motion';
import { Syringe, ArrowRight, Shield, Award, UserCheck, MessageCircle, Droplets, Check, Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CategorySearch } from '../components/CategorySearch';

const treatments = [
  {
    id: 'dermal-fillers',
    title: 'Dermal Fillers',
    urduTitle: 'ڈرمل فلرز',
    description: 'Premium hyaluronic acid fillers for natural volume enhancement',
    urduDescription: 'قدرتی حجم میں اضافے کے لیے پریمیم ہائلورونک ایسڈ فلرز',
    image: 'https://images.unsplash.com/photo-1620916566398-39f1143ab7be?w=800',
    options: [
      {
        name: 'Lips',
        urduName: 'ہونٹ',
        volumes: ['0.5ml', '1ml'],
        price: 'Rs. 38,500/ml'
      },
      {
        name: 'Cheeks',
        urduName: 'گال',
        volumes: ['1ml', '2ml'],
        price: 'Rs. 38,500/ml'
      },
      {
        name: 'Jawline',
        urduName: 'جبڑا',
        volumes: ['1ml', '2ml'],
        price: 'Rs. 38,500/ml'
      },
      {
        name: 'Chin',
        urduName: 'ٹھوڑی',
        volumes: ['1ml'],
        price: 'Rs. 38,500/ml'
      },
      {
        name: 'Under Eyes',
        urduName: 'آنکھوں کے نیچے',
        volumes: ['0.5ml', '1ml'],
        price: 'Rs. 45,000/ml'
      }
    ]
  },
  {
    id: 'botox',
    title: 'Botox Treatments',
    urduTitle: 'بوٹاکس علاج',
    description: 'Muscle relaxing injections for wrinkle reduction',
    urduDescription: 'جھریوں کو کم کرنے کے لیے پٹھوں کو آرام دینے والے انجیکشن',
    image: 'https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?w=800',
    options: [
      {
        name: 'Forehead Lines',
        urduName: 'پیشانی کی لکیریں',
        units: '20-30',
        price: 'Rs. 45,000'
      },
      {
        name: 'Frown Lines',
        urduName: 'تیور کی لکیریں',
        units: '20-25',
        price: 'Rs. 35,000'
      },
      {
        name: 'Crow\'s Feet',
        urduName: 'آنکھوں کے کنارے',
        units: '12-16',
        price: 'Rs. 35,000'
      },
      {
        name: 'Bunny Lines',
        urduName: 'ناک کی لکیریں',
        units: '4-8',
        price: 'Rs. 35,000'
      }
    ]
  }
];

const fillerInfo = [
  {
    title: "What is Hyaluronic Acid?",
    urduTitle: "ہائلورونک ایسڈ کیا ہے؟",
    content: "Hyaluronic acid is naturally present in your skin, making up about 30% of its composition. It's responsible for keeping your skin hydrated, plump, and youthful.",
    urduContent: "ہائلورونک ایسڈ قدرتی طور پر آپ کی جلد میں موجود ہوتا ہے، جو اس کی ساخت کا تقریباً 30% بناتا ہے۔"
  },
  {
    title: "How Much Filler?",
    urduTitle: "کتنا فلر؟",
    content: "1ml of filler is approximately 1/5 of a teaspoon. We use precise micro-droplet techniques to ensure natural-looking results.",
    urduContent: "1ml فلر تقریباً 1/5 چمچ کے برابر ہوتا ہے۔ ہم قدرتی نظر آنے والے نتائج کو یقینی بنانے کے لیے درست مائیکرو ڈراپلیٹ تکنیک استعمال کرتے ہیں۔"
  },
  {
    title: "Natural Results",
    urduTitle: "قدرتی نتائج",
    content: "Our approach focuses on subtle enhancement. You can always add more filler gradually to achieve your desired look.",
    urduContent: "ہمارا طریقہ کار معمولی بہتری پر مرکوز ہے۔ آپ ہمیشہ اپنی مطلوبہ ظاہری شکل حاصل کرنے کے لیے آہستہ آہستہ مزید فلر شامل کر سکتے ہیں۔"
  }
];

export function InjectableTreatments() {
  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="relative min-h-[60vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
        <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 relative z-30"
        >
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 gradient-text">
              Injectable Treatments
              <span className="block text-3xl mt-4 text-[#00B6DE]">انجکشن والے علاج</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Premium dermal fillers and muscle relaxants for natural-looking enhancement
              <span className="block mt-2 text-lg">قدرتی نظر آنے والے اضافے کے لیے پریمیم ڈرمل فلرز اور مسل ریلیکسنٹس</span>
            </p>

            <div className="grid md:grid-cols-4 gap-6 mt-12">
              <div className="glass-card p-6">
                <Shield className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Premium Products</h3>
                <p className="text-sm text-gray-400">FDA-approved fillers</p>
              </div>
              <div className="glass-card p-6">
                <Award className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Expert Doctors</h3>
                <p className="text-sm text-gray-400">UK-trained specialists</p>
              </div>
              <div className="glass-card p-6">
                <UserCheck className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Natural Results</h3>
                <p className="text-sm text-gray-400">Subtle enhancement</p>
              </div>
              <div className="glass-card p-6">
                <MessageCircle className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                <h3 className="text-lg font-semibold text-white">Free Consultation</h3>
                <p className="text-sm text-gray-400">Personalized plans</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Add Search Section */}
      <section className="py-12">
        <CategorySearch 
          category="Injectables"
          placeholder="Search Botox, fillers, thread lifts..."
          urduPlaceholder="بوٹاکس، فلرز، تھریڈ لفٹس تلاش کریں۔۔۔"
        />
      </section>

      {/* Understanding Fillers */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass-card p-8 rounded-2xl"
          >
            <div className="flex flex-col lg:flex-row gap-12">
              <div className="lg:w-1/2">
                <h2 className="text-3xl font-bold gradient-text mb-6">
                  Understanding Dermal Fillers
                  <span className="block text-xl mt-2 text-[#00B6DE]">ڈرمل فلرز کو سمجھنا</span>
                </h2>
                
                <div className="space-y-6">
                  {fillerInfo.map((info, index) => (
                    <div key={index} className="glass-card p-4">
                      <h3 className="text-lg font-semibold text-white mb-2">
                        {info.title}
                        <span className="block text-sm text-[#00B6DE]">{info.urduTitle}</span>
                      </h3>
                      <p className="text-gray-300 text-sm">{info.content}</p>
                      <p className="text-[#00B6DE] text-sm mt-1">{info.urduContent}</p>
                    </div>
                  ))}

                  <Link 
                    to="https://wa.me/923194569401"
                    className="btn-primary inline-flex items-center"
                  >
                    Book Consultation
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </Link>
                </div>
              </div>

              <div className="lg:w-1/2">
                <div className="relative aspect-square rounded-2xl overflow-hidden">
                  <img
                    src="https://images.unsplash.com/photo-1620916566398-39f1143ab7be?w=800"
                    alt="Dermal Filler Treatment"
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center">
                    <div className="text-center">
                      <div className="flex items-center justify-center gap-12">
                        <div>
                          <div className="w-24 h-24 rounded-full border-4 border-[#00B6DE] flex items-center justify-center mb-3 relative">
                            <span className="text-[#00B6DE] text-xl font-bold">1ml</span>
                            <div className="absolute -bottom-2 w-full h-0.5 bg-[#00B6DE]" />
                          </div>
                          <span className="text-white font-medium">Standard</span>
                        </div>
                        <div>
                          <div className="w-20 h-20 rounded-full border-4 border-[#00B6DE]/60 flex items-center justify-center mb-3 relative">
                            <span className="text-[#00B6DE]/60 text-lg font-bold">0.5ml</span>
                            <div className="absolute -bottom-2 w-full h-0.5 bg-[#00B6DE]/60" />
                          </div>
                          <span className="text-white/60 font-medium">Subtle</span>
                        </div>
                      </div>
                      <p className="mt-6 text-gray-300 text-sm max-w-xs mx-auto">
                        Choose your desired level of enhancement with our customizable filler options
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Treatment Options */}
      <section className="py-20 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold gradient-text mb-4">
              Our Injectable Treatments
              <span className="block text-2xl mt-2 text-[#00B6DE]">ہمارے انجکشن والے علاج</span>
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Customized treatments using premium products for natural-looking results
              <span className="block mt-2 text-lg text-[#00B6DE]">قدرتی نظر آنے والے نتائج کے لیے پریمیم پروڈکٹس کا استعمال کرتے ہوئے حسب ضرورت علاج</span>
            </p>
          </motion.div>

          <div className="space-y-12">
            {treatments.map((treatment, index) => (
              <motion.div
                key={treatment.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass-card p-8 rounded-2xl"
              >
                <div className="flex flex-col lg:flex-row gap-8">
                  <div className="lg:w-1/3">
                    <div className="relative aspect-square rounded-xl overflow-hidden">
                      <img
                        src={treatment.image}
                        alt={treatment.title}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent" />
                      <div className="absolute bottom-4 left-4 right-4">
                        <h3 className="text-2xl font-bold text-white">{treatment.title}</h3>
                        <p className="text-[#00B6DE]">{treatment.urduTitle}</p>
                      </div>
                    </div>
                  </div>

                  <div className="lg:w-2/3">
                    <p className="text-gray-300 mb-2">{treatment.description}</p>
                    <p className="text-[#00B6DE] text-sm mb-6">{treatment.urduDescription}</p>

                    <div className="grid md:grid-cols-2 gap-4">
                      {treatment.options.map((option, i) => (
                        <div key={i} className="glass-card p-4">
                          <div className="flex items-center justify-between mb-2">
                            <div>
                              <h4 className="text-white font-semibold">{option.name}</h4>
                              <p className="text-[#00B6DE] text-sm">{option.urduName}</p>
                            </div>
                            <span className="text-emerald-400 font-medium">{option.price}</span>
                          </div>
                          {option.volumes && (
                            <div className="flex items-center gap-2 text-sm text-gray-400">
                              <Droplets className="w-4 h-4" />
                              <span>Available in: {option.volumes.join(', ')}</span>
                            </div>
                          )}
                          {option.units && (
                            <div className="flex items-center gap-2 text-sm text-gray-400">
                              <Syringe className="w-4 h-4" />
                              <span>{option.units} units</span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}