import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Search, Loader2 } from 'lucide-react';
import { SearchResults } from './SearchResults';
import { semanticSearch, generateSearchResponse } from '../lib/search';

interface CategorySearchProps {
  category: string;
  placeholder?: string;
  urduPlaceholder?: string;
}

export function CategorySearch({ category, placeholder, urduPlaceholder }: CategorySearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState([]);
  const [aiResponse, setAiResponse] = useState<string | null>(null);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      setLoading(true);
      setError(null);
      setAiResponse(null);

      const searchResults = await semanticSearch(searchQuery, {
        matchThreshold: 0.5,
        matchCount: 5,
        category
      });

      if (!searchResults.length) {
        setAiResponse("No matching information found for this category.\n\nاس زمرے میں کوئی مماثل معلومات نہیں ملیں۔");
        setResults([]);
        return;
      }

      setResults(searchResults);

      const response = await generateSearchResponse(searchQuery, searchResults);
      setAiResponse(response);
    } catch (err) {
      console.error('Search error:', err);
      setError('Failed to perform search');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="glass-card p-6 rounded-2xl"
      >
        <form onSubmit={handleSearch} className="space-y-4">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={`${placeholder || 'Search treatments...'}\n${urduPlaceholder || 'علاج تلاش کریں۔۔۔'}`}
              className="w-full pl-12 pr-4 py-4 bg-gray-800/50 border border-gray-700 rounded-full text-white focus:ring-2 focus:ring-[#00B6DE] focus:border-transparent"
            />
          </div>

          <button
            type="submit"
            disabled={loading || !searchQuery.trim()}
            className="btn-primary w-full flex items-center justify-center"
          >
            {loading ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin mr-2" />
                Searching...
              </>
            ) : (
              'Search'
            )}
          </button>
        </form>

        {error && (
          <div className="mt-4 p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
            {error}
          </div>
        )}

        {aiResponse && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-8"
          >
            <h3 className="text-xl font-bold text-white mb-4">AI Response</h3>
            <div className="prose prose-invert max-w-none">
              <p className="whitespace-pre-wrap">{aiResponse}</p>
            </div>
          </motion.div>
        )}

        <SearchResults
          results={results}
          loading={loading}
          error={error}
        />
      </motion.div>
    </div>
  );
}