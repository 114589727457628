import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Clock, Heart, AlertCircle, ArrowRight, MessageCircle, Phone, Star, Shield, Award, UserCheck, CheckCircle, Syringe, Timer, Activity, Sparkles } from 'lucide-react';
import { Link } from 'react-router-dom';

const treatmentInfo = [
  {
    icon: Syringe,
    title: 'Anaesthetic',
    value: 'Local'
  },
  {
    icon: Clock,
    title: 'Results',
    value: '3 to 4 months/Results lasting lifetime'
  },
  {
    icon: Timer,
    title: 'Procedure Time',
    value: 'Usually 4 to 8 hours'
  },
  {
    icon: AlertCircle,
    title: 'Risk & Complications',
    value: 'Temporary Scarring, Bruising or Swelling, Numbness, Folliculitis'
  },
  {
    icon: Activity,
    title: 'Full Recovery',
    value: '10 to 14 days'
  },
  {
    icon: Clock,
    title: 'Back To Work',
    value: '3 to 5 days'
  }
];

const benefits = [
  "Natural-Looking Hairline",
  "Increased Hair Density and Coverage",
  "Permanent Solution to Hair Loss",
  "Ideal Hair Growth Treatment for Men and Women",
  "Targeted Treatment for Bald Spots",
  "Safe and Scientifically Proven",
  "Slows Down Future Hair Loss",
  "Instant Confidence Boost"
];

const candidates = [
  "Young men battling baldness",
  "New mums with thinning hair",
  "Men with receding hairlines",
  "Postmenopausal women with hair loss",
  "People with patchy hair loss",
  "Active men and women seeking quick recovery",
  "Stress-induced hair loss sufferers",
  "Women with genetic hair thinning",
  "Men with uneven facial hair",
  "Needing second hair transplant",
  "Men desiring perfect hairline",
  "Post-weight loss hair regrowth"
];

const process = [
  {
    title: "Personalized Consultation",
    description: "Start your journey with a comprehensive assessment by our hair specialists. We'll evaluate your hair loss pattern, discuss your medical history, and create a tailored treatment plan just for you."
  },
  {
    title: "Seamless FUE Procedure",
    description: "Experience our advanced FUE hair transplant, where individual hair follicles are carefully extracted and implanted for a natural look. Local anesthesia ensures your comfort throughout the process."
  },
  {
    title: "Enhanced Restoration Techniques",
    description: "Maximize your results with our combined approach. We integrate finasteride, minoxidil, PRP therapy, and exosome treatments to boost hair regrowth and protect your newly transplanted hair."
  },
  {
    title: "Ongoing Care and Support",
    description: "After your procedure, receive detailed aftercare instructions and regular follow-ups to monitor your progress. Our dedicated team is here to ensure you achieve the full, vibrant hair you desire."
  }
];

const treatments = [
  {
    title: "Minoxidil",
    description: "A clinically proven topical solution designed to stimulate hair growth. By enhancing blood circulation to the scalp, it promotes accelerated recovery and fuller results following hair transplant procedures."
  },
  {
    title: "Finasteride",
    description: "A powerful DHT blocker that effectively prevents further hair loss in men. By inhibiting the hormone responsible for male pattern baldness, it supports the longevity and vitality of your transplanted hair."
  },
  {
    title: "PRP for Hair Loss",
    description: "PRP (Platelet-Rich Plasma) therapy utilizes your body's own growth factors to enhance hair restoration. This innovative treatment improves hair density and supports the health of transplanted follicles."
  },
  {
    title: "Exosomes for Hair Loss",
    description: "Exosome therapy is a groundbreaking treatment that promotes cellular regeneration and rejuvenation of hair follicles. This advanced approach enhances the efficacy of hair restoration."
  }
];

const reviews = [
  {
    name: "Bilal Hussain",
    date: "June 25, 2024",
    rating: 5,
    text: "I have taken several sessions of PRP and micro needling also a session of exosomes. The results are amazing. The experience here and staff are the best. Would recommend 10/10."
  },
  {
    name: "Safdar Iqbal",
    date: "February 4, 2024",
    rating: 5,
    text: "A jewel in a town highly recommended most professional staff very caring Dr Hamdah utmost level of equipment walk in till walk out each and every step they are with you I'm very satisfied with there professional skills must visit you will get definitely 💯 satisfied."
  },
  {
    name: "rafia razzaq",
    date: "October 3, 2024",
    rating: 5,
    text: "I have recently visited this clinic, I came to know that Dr. Hamdah is the best ever thing on this planet, she is genuine& sincere to you and your skin, she always suggest what is best for your skin type."
  }
];

export function HairTransplant() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Hair Transplant in Lahore - FUE & DHI Hair Transplant | Glow & Go</title>
        <meta name="description" content="Get natural-looking hair transplant in Lahore using advanced FUE and DHI techniques. Expert surgeons, world-class facilities, and guaranteed results at Glow & Go." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.glowandgo.pk/hair-transplant" />
        <meta property="og:title" content="Hair Transplant in Lahore - FUE & DHI Hair Transplant | Glow & Go" />
        <meta property="og:description" content="Get natural-looking hair transplant in Lahore using advanced FUE and DHI techniques. Expert surgeons, world-class facilities, and guaranteed results at Glow & Go." />
        <meta property="og:image" content="https://images.unsplash.com/photo-1626954079979-ec4f7b05e032?w=1200" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.glowandgo.pk/hair-transplant" />
        <meta name="twitter:title" content="Hair Transplant in Lahore - FUE & DHI Hair Transplant | Glow & Go" />
        <meta name="twitter:description" content="Get natural-looking hair transplant in Lahore using advanced FUE and DHI techniques. Expert surgeons, world-class facilities, and guaranteed results at Glow & Go." />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1626954079979-ec4f7b05e032?w=1200" />
      </Helmet>

      <div className="min-h-screen bg-black">
        {/* Hero Section */}
        <div className="relative min-h-[60vh] flex items-center justify-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-800 via-black to-black pt-20">
          <div className="absolute inset-0 bg-gradient-to-b from-[#00B6DE]/10 to-transparent z-20"></div>
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="container mx-auto px-4 relative z-30"
          >
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-5xl md:text-6xl font-bold mb-6 gradient-text">
                Hair Transplant
                <span className="block text-3xl mt-4 text-[#00B6DE]">Say Yes to a Full Head of Hair</span>
              </h1>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
                Hair Loss can be tough and lower your confidence. At Glow & GO Hair Clinic, a team of top hair specialists help with thinning hair, bald patches, and hair loss from aging with FUE hair transplant ensuring premium care and world-class service without any compromise on quality.
              </p>

              <div className="grid md:grid-cols-3 gap-6 mt-12">
                <div className="glass-card p-6">
                  <Shield className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                  <h3 className="text-lg font-semibold text-white">Premium Care</h3>
                  <p className="text-sm text-gray-400">World-class service</p>
                </div>
                <div className="glass-card p-6">
                  <Award className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                  <h3 className="text-lg font-semibold text-white">Expert Doctors</h3>
                  <p className="text-sm text-gray-400">Specialized team</p>
                </div>
                <div className="glass-card p-6">
                  <UserCheck className="w-8 h-8 text-[#00B6DE] mx-auto mb-4" />
                  <h3 className="text-lg font-semibold text-white">Natural Results</h3>
                  <p className="text-sm text-gray-400">Lifetime guarantee</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Treatment Info */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-3 gap-6">
              {treatmentInfo.map((info, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-6"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <div className="w-12 h-12 rounded-full bg-[#00B6DE]/20 flex items-center justify-center">
                      <info.icon className="w-6 h-6 text-[#00B6DE]" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white">{info.title}</h3>
                      <p className="text-sm text-gray-300">{info.value}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="py-20 bg-gray-900/50">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-bold gradient-text mb-4">
                Why Choose Hair Transplant?
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                A hair transplant such as FUE (Follicular Unit Excision), is a simple surgical procedure where hair follicles from the back of your head are moved to areas with thinning or bald spots. Done under local anesthesia, it ensures your transplanted hair looks natural, blends perfectly with your existing hair, and grows just like your real hair.
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-4 flex items-center gap-3"
                >
                  <CheckCircle className="w-5 h-5 text-[#00B6DE] flex-shrink-0" />
                  <p className="text-gray-300">{benefit}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Treatment Process */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-bold gradient-text mb-4">
                FUE Hair Transplant Process at Glow & GO
              </h2>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-8">
              {process.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-6"
                >
                  <div className="flex items-start gap-4">
                    <div className="w-8 h-8 rounded-full bg-[#00B6DE]/20 flex items-center justify-center flex-shrink-0">
                      <span className="text-[#00B6DE] font-bold">{index + 1}</span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
                      <p className="text-gray-300">{step.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section className="py-20 bg-gray-900/50">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="glass-card p-8 rounded-2xl text-center"
            >
              <h2 className="text-3xl font-bold gradient-text mb-4">
                FUE Hair Transplant Cost
              </h2>
              <div className="text-4xl font-bold text-[#00B6DE] mb-6">
                Starting from PKR 4,50,000
              </div>
              <p className="text-gray-300 mb-8">
                Full-Scalp Revitalization and Hair Restoration
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <a 
                  href="https://wa.me/923194569401"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary inline-flex items-center justify-center"
                >
                  <MessageCircle className="w-5 h-5 mr-2" />
                  WhatsApp Consultation
                </a>
                <a 
                  href="tel:+924237896975"
                  className="btn-secondary inline-flex items-center justify-center"
                >
                  <Phone className="w-5 h-5 mr-2" />
                  Call: 042-37896975
                </a>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Treatments Section */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-bold gradient-text mb-4">
                Powerful Combination of Hair Restoration Treatments
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                At Glow & Go, we provide advanced solutions like FUE hair surgery, hair grafting, and powerful growth therapies.
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-8">
              {treatments.map((treatment, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-6"
                >
                  <h3 className="text-xl font-semibold text-white mb-4">{treatment.title}</h3>
                  <p className="text-gray-300">{treatment.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Candidates Section */}
        <section className="py-20 bg-gray-900/50">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-bold gradient-text mb-4">
                Ideal Candidates for Hair Transplant
              </h2>
            </motion.div>

            <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-4">
              {candidates.map((candidate, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-4 flex items-center gap-3"
                >
                  <Sparkles className="w-4 h-4 text-[#00B6DE] flex-shrink-0" />
                  <p className="text-gray-300">{candidate}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Reviews Section */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-bold gradient-text mb-4">
                Don't Just Take Our Word For It
              </h2>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {reviews.map((review, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass-card p-6"
                >
                  <div className="flex items-center mb-4">
                    {[...Array(review.rating)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                    ))}
                  </div>
                  <p className="text-gray-300 mb-4">{review.text}</p>
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-white font-semibold">{review.name}</span>
                    <span className="text-gray-400">{review.date}</span>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="py-20 bg-gray-900/50">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="glass-card p-8 rounded-2xl text-center"
            >
              <h2 className="text-3xl font-bold gradient-text mb-4">
                Book Your Consultation Today
              </h2>
              <p className="text-gray-300 max-w-2xl mx-auto mb-8">
                Take the first step towards reclaiming your hair. Our experts will create a personalized treatment plan for you.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <a 
                  href="https://wa.me/923194569401"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary inline-flex items-center justify-center"
                >
                  <MessageCircle className="w-5 h-5 mr-2" />
                  WhatsApp Consultation
                </a>
                <a 
                  href="tel:+924237896975"
                  className="btn-secondary inline-flex items-center justify-center"
                >
                  <Phone className="w-5 h-5 mr-2" />
                  Call: 042-37896975
                </a>
              </div>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
}